
    // Do not modify this file by hand!
    // Re-generate this file by running lit-localize

    import {html} from 'lit';
    

    /* eslint-disable no-irregular-whitespace */
    /* eslint-disable @typescript-eslint/no-explicit-any */

    export const templates = {
      'h0e60f0986075c2fd': html`通过申请此许可证，Tenable 可能会向您发送有关其产品和服务的电子邮件通讯。您可以随时使用发送给您的电子邮件页脚中的退订链接选择不接收这些通讯。您还可以通过访问<a target="_blank" href="https://info.tenable.com/SubscriptionManagement.html">订阅管理页面</a>来管理您的 Tenable 电子邮件首选项。`,
'h5d94556d32fdee9e': html`Tenable 只会根据其<a target="_blank" href="https://www.tenable.com/privacy-policy">隐私政策</a>规定处理您的个人数据。`,
'h97a056af7968d75c': html`通过注册此试用许可证，Tenable 可能会向您发送有关其产品和服务的电子邮件通讯。您可以随时使用发送给您的电子邮件页脚中的退订链接选择不接收这些通讯。您还可以通过访问<a target="_blank" href="https://info.tenable.com/SubscriptionManagement.html">订阅管理页面</a>来管理您的 Tenable 电子邮件首选项。`,
'hb2f7081d6bbb9ba0': html`我同意 Tenable 的<a href="https://static.tenable.com/prod_docs/tenable_slas.html">软件许可协议</a>`,
's09205907b5b56cda': `没有`,
's0adb3f5e5e594e43': `课程名称为必填项`,
's0f3489ff0bc2e14f': `上传 PDF 为必填项`,
's0fbf6dc6a1966408': `下一步`,
's12e486740e2e33fc': `企业名称为必填项`,
's150bac76e4f85b3c': `地区为必填项`,
's15361efa1eef6aee': `开始`,
's1cdeecda0baf365e': `发布`,
's1dda911063939e05': `您的试用期已延长。`,
's1ed5c670ff346e93': `留言为必填项`,
's1f5a2beee27d45e7': `客户可能已经了解他们已获得您企业中某人的授权以进行扫描。请确认您已与相关业务利益相关者核实，以确定此扫描是否已获得授权。`,
's24233c7c75e9ee19': `请注意，反馈仅适用于文档，不适用于 Tenable 产品。`,
's2468b69d10791c82': `城市`,
's27707a7616f7f789': `名字为必填项`,
's27cc88818cc46ff6': `稳定`,
's2c57913ffd36d298': `名字`,
's2ea2118efa449d32': `上传课程大纲或文档 PDF`,
's2eb3c51d71b9f608': `激活码格式无效`,
's30699ee91122500e': `我们将很快就您的请求与您联系。`,
's31008bbe2c3076e5': `公司规模（员工人数）`,
's32fc186d3b40fb7d': `企业名称`,
's39888fc990ed085e': `您必须与相关利益相关者核实才能继续。`,
's3b8dc2a2dfe650e3': `一旦您提交此表格，我们将调查我们的哪些客户发起了此扫描活动。请在下面提供一个电子邮件地址，以便我们在调查完成后跟进。`,
's404951d60833268e': `页面 URL 为必填项`,
's42cbd8dca939a9c7': `查看`,
's4362571c6638089a': `学校/培训项目名称为必填项`,
's483a20681be190ae': `课程名称`,
's4f2540795dacf546': `超过最大留言字数`,
's5209870f843f5cd1': `目标 IP 不能为空`,
's5855112288e12328': `职称为必填项`,
's5b7873343b9dc4eb': `未经授权扫描流量停止的日期/时间（在您当地的时区）？`,
's5d0cc12e0667faa7': `学校/培训项目名称`,
's5ef6d725815017ce': `哪些 IP 是未经授权扫描的目标？`,
's686306cdb839fb8d': `正在发送…`,
's6b986b4370cf95cf': `请提供以下信息以创建帐户并开始试用。`,
's6b9a88ee6c526aa4': `源 IP 不能为空`,
's6dcf1b198660d558': `这个页面对您有帮助吗？`,
's6faf53a851ae1ef8': `电子邮件地址无效`,
's745d8397b36781a7': `要开始试用，请提供您的企业电子邮件地址。`,
's76b6c6ef5bc06202': `公开发售`,
's776925a2c81b7a63': `企业电子邮件`,
's78df1fa22c512d7d': `留言（可选）`,
's79df2775a8139811': `条款协议为必填项`,
's7cb4e55c59663b1c': `公司`,
's80a0e23d9c7eea56': `地址为必填项`,
's81ec7a6847534d5e': `返回`,
's85e492de89c8ae2c': `公司规模为必填项`,
's8868de3e06abe230': `电子邮件无效`,
's8a830d13f8d3298b': `开始试用`,
's912d50b0bb095241': `电子邮件地址不能为空`,
's95a75a2392af5780': `姓氏`,
's99f110d27e30b289': `职称`,
's9cf7402fe920f7d1': `国家`,
'sa21690c4800aeb69': `页面是否有帮助为必填项`,
'sa4343664d7b15d0c': `上传非营利组织身份 PDF`,
'sa4cdfd30561e69a1': `名字无效`,
'sa9c6d50e25c88911': `姓氏为必填项`,
'sae96e6ca5de9220c': `激活码不能为空`,
'saf84ec86139d349e': `留言`,
'sb37fd819bd638708': `请留言…`,
'sb3d4f79d9d8b71e5': `提交`,
'sb51270e19988f9bd': `地区`,
'sb7567af23b8f5703': `谢谢！`,
'sb7c9b2995d6869b1': `姓氏为必填项`,
'sb839a044bb672d70': `国家为必填项`,
'sbaf0ff2390822c90': `您的产品现在已启用 Nessus Expert 评估。`,
'sbd66ac1792782ee6': `要开始试用，请下载并安装 Nessus。`,
'sbde039f6fe3847bf': `开始日期不能为空`,
'sc16e00a7a8b2fde2': `后退`,
'sc46caeb9d1bc8fc6': `提交失败`,
'sc9446ac9849aa293': `邮政编码`,
'scb489a1a173ac3f0': `有`,
'scf226d2c20f172c2': `电话为必填项`,
'sd1ae08b28f9defa9': `停止日期不能为空`,
'sd1f44f1a8bc20e67': `电子邮件`,
'sd33e190b8e41dc5f': `未经授权扫描的来源 IP 是什么？`,
'sd3d0ca1bec5003d7': `城市为必填项`,
'sda8ea7031a8a7d69': `名字为必填项`,
'sdc79ece8adeaa363': `姓氏无效`,
'sdcf8c65961c70674': `我已与相关业务利益相关者核实过，但此扫描未获授权。`,
'sde6ffc33e0ca1f9b': `请使用 CIDR 表示法（即：0.0.0.0/24 或 1.1.1.1/32）`,
'sdfb2b12b255de30c': `已复制！`,
'se032693d59c82e26': `激活码`,
'se1ad99292eaa4000': `提交请求`,
'se7c56a68f0ecf6e9': `正在提交…`,
'se8563c7e9107f391': `IP 地址无效`,
'sed7161a6bc9e01f6': `电子邮件地址为必填项`,
'sedabde95323d0cc2': `电子邮件无效`,
'sf1af788523eecd55': `立即下载`,
'sf1bc83d2509bafcf': `电话`,
'sf1d7c751277ec33c': `感谢您的反馈意见！`,
'sf4122b220926be97': `版本`,
'sf820da2caa9be44b': `未经授权扫描流量开始的日期/时间（在您当地的时区）？`,
'sf9286dfc7b09c4c3': `电子邮件地址被禁/电子邮件地址无效`,
'sfcdd8e672c75b713': `地址`,
'sfd76061a1ae701dd': `公司为必填项`,
'sff6467521565aa6d': `产品为必填项`,
'sffe2914d4b7c91ee': `抢先体验`,
's5f7a4982df278fbe': `Customer Name cannot be blank`,
's77d3ba42477d1e37': `Customer Email cannot be blank`,
's67da547a401b53a2': `Customer Organization cannot be blank`,
's4c672206f89e3a5a': `Output of OS Fingerprint plugin cannot be blank`,
's7e2003487616c0be': `Actual operating system and version running on asset cannot be blank`,
'sd2b571fd3bc03be2': `Product category cannot be blank`,
'sf1e5f5e4fdd3bba0': `Customer Name`,
's23a3125df912b8f9': `Customer Email`,
'se931b3bbed6e76fc': `Customer Organization`,
'scbf29ce484222325': ``,
's5d348d92f624a8d4': `Output of OS Fingerprint plugin`,
'sa425ce5a226eaca0': `Actual operating system and version running on asset`,
's5af6ae0f37cdcebc': `Product category`,
    };
  