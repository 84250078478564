import { html, LitElement } from 'lit';
import { msg, updateWhenLocaleChanges } from '@lit/localize';

class IconCheck extends LitElement {
  static properties = {
    color: {
      type: String,
    },
    width: {
      type: String,
    },
  };

  constructor() {
    super();
    this.color = '#f87b1e';
    this.width = '6em';
    updateWhenLocaleChanges(this);
  }

  render() {
    return html`
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 80 80" width="${this.width}" stroke="${this.color}" stroke-width="1.5" fill="transparent">
        <title>${msg('Check')}</title>
        <polygon points="58.99 18.24 32.49 44.74 21.01 33.26 12.5 41.77 32.49 61.76 67.5 26.75 58.99 18.24" stroke-miterlimit="10"/>
        <line x1="65.83" y1="25.08" x2="30.82" y2="60.09" stroke-miterlimit="10"/>
        <circle cx="40" cy="40" r="36.53" stroke-linecap="round" stroke-miterlimit="10"/>
      </svg>
    `;
  }
}
customElements.define('tenable-icon-check', IconCheck);
