
    // Do not modify this file by hand!
    // Re-generate this file by running lit-localize

    import {html} from 'lit';
    

    /* eslint-disable no-irregular-whitespace */
    /* eslint-disable @typescript-eslint/no-explicit-any */

    export const templates = {
      'h0e60f0986075c2fd': html`Ao solicitar esta licença, você poderá receber da Tenable comunicações por e-mail sobre seus produtos e serviços. Você pode optar por cancelar essas comunicações a qualquer momento usando o link de cancelamento localizado no rodapé dos e-mails recebidos. Você também pode gerenciar suas preferências de e-mail da Tenable acessando a <a target="_blank" href="https://info.tenable.com/SubscriptionManagement.html">página de gerenciamento de assinaturas</a>.`,
'h5d94556d32fdee9e': html`A Tenable processará os seus dados pessoais estritamente de acordo com a sua <a target="_blank" href="https://www.tenable.com/privacy-policy">Política de Privacidade</a>.`,
'h97a056af7968d75c': html`Ao se registrar para esta licença de avaliação, você poderá receber da Tenable comunicações por e-mail sobre seus produtos e serviços. Você pode optar por cancelar essas comunicações a qualquer momento usando o link de cancelamento localizado no rodapé dos e-mails recebidos. Você também pode gerenciar suas preferências de e-mail da Tenable acessando a <a target="_blank" href="https://info.tenable.com/SubscriptionManagement.html">página de gerenciamento de assinaturas</a>.`,
'hb2f7081d6bbb9ba0': html`Eu concordo com os <a href="https://static.tenable.com/prod_docs/tenable_slas.html">contratos de licença de software</a> da Tenable.`,
's09205907b5b56cda': `Não`,
's0adb3f5e5e594e43': `O título do curso é obrigatório`,
's0f3489ff0bc2e14f': `Fazer upload do PDF é obrigatório`,
's0fbf6dc6a1966408': `Avançar`,
's12e486740e2e33fc': `O nome da organização é obrigatório`,
's150bac76e4f85b3c': `A região é obrigatória`,
's15361efa1eef6aee': `Começar`,
's1cdeecda0baf365e': `Lançamento`,
's1dda911063939e05': `Sua avaliação foi prorrogada.`,
's1ed5c670ff346e93': `O comentário é obrigatório`,
's1f5a2beee27d45e7': `Um cliente pode ter dado continuidade por entender que tinha autorização de uma pessoa da organização para fazer a verificação. Confirme se você conferiu com as partes interessadas relacionadas do negócio se essa verificação foi autorizada.`,
's24233c7c75e9ee19': `Observe que o feedback é apenas para a documentação, não para os produtos Tenable.`,
's2468b69d10791c82': `Cidade`,
's27707a7616f7f789': `O nome é obrigatório`,
's27cc88818cc46ff6': `Estável`,
's2c57913ffd36d298': `Nome`,
's2ea2118efa449d32': `Fazer upload do PDF com a documentação ou o currículo do curso`,
's2eb3c51d71b9f608': `Formato do código de ativação inválido`,
's30699ee91122500e': `Entraremos em contato em breve referente à sua solicitação.`,
's31008bbe2c3076e5': `Porte da empresa (funcionários)`,
's32fc186d3b40fb7d': `Nome da organização`,
's39888fc990ed085e': `Você precisa verificar com as partes interessadas relacionadas para prosseguir.`,
's3b8dc2a2dfe650e3': `Depois que você enviar este formulário, investigaremos qual dos nossos clientes iniciou essa atividade de verificação. Indique abaixo um endereço de e-mail para que possamos fazer o acompanhamento após o término da investigação.`,
's404951d60833268e': `O URL da página é obrigatório`,
's42cbd8dca939a9c7': `Verificar`,
's4362571c6638089a': `O nome da escola/programa de treinamento é obrigatório`,
's483a20681be190ae': `Título do curso`,
's4f2540795dacf546': `Excede o tamanho máximo dos comentários`,
's5209870f843f5cd1': `Os IPs-alvo não podem ficar em branco`,
's5855112288e12328': `O cargo é obrigatório`,
's5b7873343b9dc4eb': `Data/hora (no fuso horário local) em que o tráfego da verificação não autorizada parou.`,
's5d0cc12e0667faa7': `Nome da escola/programa de treinamento`,
's5ef6d725815017ce': `Quais IPs foram alvos na verificação não autorizada?`,
's686306cdb839fb8d': `Enviando…`,
's6b986b4370cf95cf': `Forneça as seguintes informações para criar uma conta e iniciar sua avaliação.`,
's6b9a88ee6c526aa4': `Os IPs de origem não podem ficar em branco`,
's6dcf1b198660d558': `Esta página ajudou você?`,
's6faf53a851ae1ef8': `Endereço de e-mail inválido`,
's745d8397b36781a7': `Para iniciar uma avaliação, insira seu endereço de e-mail comercial.`,
's76b6c6ef5bc06202': `Disponibilidade geral`,
's776925a2c81b7a63': `E-mail comercial`,
's78df1fa22c512d7d': `Comentário (opcional)`,
's79df2775a8139811': `Concordar com os termos é obrigatório`,
's7cb4e55c59663b1c': `Empresa`,
's80a0e23d9c7eea56': `O endereço é obrigatório`,
's81ec7a6847534d5e': `Voltar`,
's85e492de89c8ae2c': `O porte da empresa é obrigatório`,
's8868de3e06abe230': `E-mail inválido`,
's8a830d13f8d3298b': `Iniciar avaliação`,
's912d50b0bb095241': `O endereço de e-mail não pode ficar em branco`,
's95a75a2392af5780': `Sobrenome`,
's99f110d27e30b289': `Cargo`,
's9cf7402fe920f7d1': `País`,
'sa21690c4800aeb69': `Uma página útil é obrigatória`,
'sa4343664d7b15d0c': `Fazer upload do PDF com o status sem fins lucrativos`,
'sa4cdfd30561e69a1': `Nome inválido`,
'sa9c6d50e25c88911': `O sobrenome é obrigatório`,
'sae96e6ca5de9220c': `O código de ativação não pode ficar em branco`,
'saf84ec86139d349e': `Comentário`,
'sb37fd819bd638708': `Deixe um comentário…`,
'sb3d4f79d9d8b71e5': `Enviar`,
'sb51270e19988f9bd': `Região`,
'sb7567af23b8f5703': `Obrigado!`,
'sb7c9b2995d6869b1': `O sobrenome é obrigatório`,
'sb839a044bb672d70': `O país é obrigatório`,
'sbaf0ff2390822c90': `Seu produto agora tem a avaliação do Nessus Expert habilitada.`,
'sbd66ac1792782ee6': `Para iniciar sua avaliação, faça download e instale o Nessus.`,
'sbde039f6fe3847bf': `A data de início não pode ficar em branco`,
'sc16e00a7a8b2fde2': `Voltar`,
'sc46caeb9d1bc8fc6': `Falha no envio`,
'sc9446ac9849aa293': `CEP`,
'scb489a1a173ac3f0': `Sim`,
'scf226d2c20f172c2': `O telefone é obrigatório`,
'sd1ae08b28f9defa9': `A data de interrupção não pode ficar em branco`,
'sd1f44f1a8bc20e67': `E-mail`,
'sd33e190b8e41dc5f': `Quais foram os IPs de origem a partir dos quais a verificação não autorizada foi originada?`,
'sd3d0ca1bec5003d7': `A cidade é obrigatória`,
'sda8ea7031a8a7d69': `O nome é obrigatório`,
'sdc79ece8adeaa363': `Sobrenome inválido`,
'sdcf8c65961c70674': `Eu verifiquei com as partes interessadas relacionadas do negócio e essa verificação não foi autorizada.`,
'sde6ffc33e0ca1f9b': `Use a notação CIDR (ex.: 0.0.0.0/24 ou 1.1.1.1/32)`,
'sdfb2b12b255de30c': `Copiado!`,
'se032693d59c82e26': `Código de ativação`,
'se1ad99292eaa4000': `Enviar solicitação`,
'se7c56a68f0ecf6e9': `Enviando…`,
'se8563c7e9107f391': `Endereço IP inválido`,
'sed7161a6bc9e01f6': `O endereço de e-mail é obrigatório`,
'sedabde95323d0cc2': `O e-mail não é válido`,
'sf1af788523eecd55': `Fazer download agora`,
'sf1bc83d2509bafcf': `Telefone`,
'sf1d7c751277ec33c': `Obrigado pelo seu feedback!`,
'sf4122b220926be97': `Versão`,
'sf820da2caa9be44b': `Data/hora (no fuso horário local) em que o tráfego da verificação não autorizada iniciou.`,
'sf9286dfc7b09c4c3': `Endereço de e-mail proibido/endereço de e-mail inválido`,
'sfcdd8e672c75b713': `Endereço`,
'sfd76061a1ae701dd': `A empresa é obrigatória`,
'sff6467521565aa6d': `O produto é obrigatório`,
'sffe2914d4b7c91ee': `Acesso antecipado`,
's5f7a4982df278fbe': `Customer Name cannot be blank`,
's77d3ba42477d1e37': `Customer Email cannot be blank`,
's67da547a401b53a2': `Customer Organization cannot be blank`,
's4c672206f89e3a5a': `Output of OS Fingerprint plugin cannot be blank`,
's7e2003487616c0be': `Actual operating system and version running on asset cannot be blank`,
'sd2b571fd3bc03be2': `Product category cannot be blank`,
'sf1e5f5e4fdd3bba0': `Customer Name`,
's23a3125df912b8f9': `Customer Email`,
'se931b3bbed6e76fc': `Customer Organization`,
'scbf29ce484222325': ``,
's5d348d92f624a8d4': `Output of OS Fingerprint plugin`,
'sa425ce5a226eaca0': `Actual operating system and version running on asset`,
's5af6ae0f37cdcebc': `Product category`,
    };
  