import { html, LitElement } from 'lit';
import '../../context/step-wizard-context/StepWizardContextProvider';
import styles from './styles';

class StepWizard extends LitElement {
  static styles = styles;

  static properties = {
    activeStep: {
      type: Number,
      reflect: true,
      attribute: 'active-step',
    },
    totalSteps: {
      type: Number,
      reflect: true,
      attribute: 'total-steps',
    },
  };

  constructor() {
    super();
    this.activeStep = 0;
    this.totalSteps = 0;
  }

  get slottedChildren() {
    const slot = this.shadowRoot.querySelector('slot');
    return slot.assignedElements({ flatten: true });
  }

  handleSlotchange(e) {
    const childNodes = e.target.assignedElements({ flatten: true });
    this.updateSteps(childNodes);
  }

  next() {
    this.activeStep += 1;
    this.updateSteps(this.slottedChildren);
  }

  previous() {
    this.activeStep -= 1;
    this.updateSteps(this.slottedChildren);
  }

  setActiveStep(event) {
    this.activeStep = event?.detail?.step ?? this.activeStep;
    this.updateSteps(this.slottedChildren);
  }

  updateSteps(childNodes) {
    this.totalSteps = childNodes?.length ?? 0;

    childNodes.forEach((child, index) => {
      if (index === this.activeStep) {
        child.style.display = 'block';
        child.setAttribute('is-active-step', true);
      }

      if (index !== this.activeStep) {
        child.style.display = 'none';
        child.setAttribute('is-active-step', false);
      }

      child.setAttribute('step', index);
      child.setAttribute('active-step', this.activeStep);
      child.setAttribute('total-steps', this.totalSteps);
    });
  }

  render() {
    return html`
      <tenable-step-wizard-provider .value=${{ totalSteps: this.totalSteps, activeStep: this.activeStep }}>
        <div
          @next-step=${(e) => this.next(e)}
          @previous-step=${(e) => this.previous(e)}
          @set-active-step=${(e) => this.setActiveStep(e)}
        >
          <slot @slotchange=${this.handleSlotchange}></slot>
        </div>
      </tenable-step-wizard-provider>
    `;
  }
}
customElements.define('tenable-step-wizard', StepWizard);
