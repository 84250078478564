import { ContextConsumer } from '@lit-labs/context';
import stepWizardConext from './StepWizardConext';

const StepWizardContextConsumer = (host, options = {}) => new ContextConsumer(host, {
  context: stepWizardConext,
  subscribe: true,
  ...options,
});

export default StepWizardContextConsumer;
