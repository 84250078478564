import { html, LitElement } from 'lit';
import { msg, updateWhenLocaleChanges } from '@lit/localize';
import * as copy from 'copy-to-clipboard';
import styles from './styles';
import '../button/Button';
import '../tooltip/Tooltip';
import '../icons/IconCopy';

class CopyButton extends LitElement {
  static styles = styles;

  static properties = {
    message: {
      type: String,
    },
    data: {
      type: String,
    },
    hideTooltip: {
      type: Boolean,
      state: true,
    },
  };

  constructor() {
    super();
    updateWhenLocaleChanges(this);

    this.message = null;
    this.data = '';
    this.hideTooltip = true;
  }

  handleCopy() {
    copy(this.data);
    this.hideTooltip = false;
    setTimeout(() => { this.hideTooltip = true; }, 1000);
  }

  render() {
    return html`
      <tenable-tooltip message="${this.message || msg('Copied!')}" .hidden=${this.hideTooltip}>
        <tenable-button color="link" @button-click=${() => this.handleCopy()}>
          <tenable-icon-copy></tenable-icon-copy>
        </tenable-button>
      </tenable-tooltip>
    `;
  }
}
customElements.define('tenable-copy-button', CopyButton);
