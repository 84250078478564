import {
  html,
  LitElement,
} from 'lit';
import styles from './styles';

class FormGroup extends LitElement {
  static styles = styles;

  render() {
    return html`
      <div class="form-group">
        <slot></slot>
      </div>
    `;
  }
}
customElements.define('tenable-form-group', FormGroup);
