import { css } from 'lit';

export default [
  css`
  ul {
    color: #ffffff;
    background: var(--tenable-form-error-color, #e24301);
    border-radius: 3px;
    padding: .5rem;
    margin-bottom: 1rem;
    list-style: none;
  }

  li {
    color: #ffffff;
    line-height: 1.2;
  }
  `,
];
