import { msg } from '@lit/localize';

const messages = () => ({
  name: {
    required: msg('Customer Name cannot be blank'),
  },
  email: {
    email: msg('Invalid email address'),
    required: msg('Customer Email cannot be blank'),
  },
  organization: {
    required: msg('Customer Organization cannot be blank'),
  },
  output: {
    required: msg('Output of OS Fingerprint plugin cannot be blank'),
  },
  version: {
    required: msg('Actual operating system and version running on asset cannot be blank'),
  },
  category: {
    required: msg('Product category cannot be blank'),
  },
});

export default messages;
