import { css } from 'lit';

export default [
  css`
  select {
    background: var(--tenable-input-background, #ffffff);
    width: var(--tenable-input-width, 100%);
    border: var(--tenable-input-border, 1px solid #ccc);
    border-radius: var(--tenable-input-border-radius, 3px);
    padding: var(--tenable-input-padding, .5rem);
    height: var(--tenable-input-height, 2.5rem);
    display: var(--tenable-input-display, block !important);
    box-shadow: var(--tenable-input-box-shadow, inset 0 1px 2px #0000000d);
    font: var(--tenable-input-font, inherit);
    line-height: var(--tenable-input-line-height, normal);
    box-sizing:  var(--tenable-input-box-sizing, border-box);
  }

  select:focus {
    border-color: var(--tenable-input-focus-border-color, #86b7fe);
    box-shadow: var(--tenable-input-focus-box-shadow, 0 0 0 .15rem rgba(13,110,253,.25));
    outline: 0;
  }

  select.invalid {
    border-color: var(--tenable-input-invalid-border-color, #e24301);
  }

  select.placeholder {
    color: var(--tenable-input-placeholder-color, #868b94);
  }

  label {
    margin: var(--tenable-label-margin, 0 0 .25rem);
    display: block;
    font-weight: var(--tenable-label-font-weight, 600);
    color: var(--tenable-label-color, #425363);
  }
  `,
];
