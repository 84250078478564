import { css } from 'lit';

export default [
  css`
  .tooltip {
    position: relative;
  }

  .tooltip:before {
    position: absolute;
    display: block;

    /* pull content from data */
    content: attr(data-message);
    white-space: pre-wrap;

    /* tooltip vertically center */
    top: 50%;
    transform: translateY(-50%);

    /* basic styles */
    max-width: var(--tenable-tooltip-max-width, 200px);
    padding: 0.35rem .5rem;
    border-radius: 4px;
    background: var(--tenable-tooltip-background, #000000);
    color: var(--tenable-tooltip-color, #ffffff);
    text-align: var(--tenable-tooltip-text-align, center);
    font-size: .8rem;

    visibility: hidden;
    opacity: 0;
    transition: .3s;
  }

  .tooltip-left.tooltip:before {
    right: 100%;
    margin-right: 10px;
  }

  .tooltip-right.tooltip:before {
    left: 100%;
    margin-left: 10px;
  }

  .tooltip-above.tooltip:before {
    bottom: 100%;
    top: auto;
    margin-bottom: 10px;
    transform: none;
  }

  .tooltip-below.tooltip:before {
    top: 100%;
    top: auto;
    margin-top: 10px;
    transform: none;
  }

  .tooltip:hover:before {
    display: block;
    opacity: 1;
    visibility: visible;
  }

  .tooltip:after {
    opacity: 0;
    transition: .3s;
  }

  .tooltip:hover:after {
    opacity: 1;
  }
  `,
];
