import { escapeHTML, getProductName, getAppsArray } from './helpers';

const getTrackingName = (id) => {
  const products = {
    tio: 'Tenable.io',
    vm: 'Tenable.io',
    consec: 'Container Security',
    was: 'Web App Scanning',
    lumin: 'Lumin',
    nessus: 'Nessus Pro',
    cns: 'Tenable.cs',
    essentials: 'Nessus Essentials',
    expert: 'Nessus Expert',
  };

  return products[id];
};

const getTrackingFields = ({ product }) => {
  const trackingFeilds = {
    pid: escapeHTML(window?.pid),
    utm_source: escapeHTML(window?.utm_source),
    utm_campaign: escapeHTML(window?.utm_campaign),
    utm_medium: escapeHTML(window?.utm_medium),
    utm_content: escapeHTML(window?.utm_content),
    utm_promoter: escapeHTML(window?.utm_promoter),
    utm_term: escapeHTML(window?.utm_term),
    alert_email: escapeHTML(window?.alert_email),
    _mkto_trk: window?.tnsTools?.getCookie('_mkto_trk') ?? '',
    mkt_tok: window?.tnsTools?.getParameterByName('mkt_tok') ?? '',
    lookbook: escapeHTML(window?.lookbook),
    gclid: window?.tnsTools?.getParameterByName('gclid') || '',
    country: escapeHTML(window?.tns?.country) || window?.tnsTools?.getCookie('tns_country') || 'US',
    region: escapeHTML(window?.tns?.region) || '',
    zip: escapeHTML(window?.tns?.zip) || '',
    apps: getAppsArray(product),
    tempProductInterest: getProductName(product) || '',
    gtm: {
      category: `${getTrackingName(product)} Eval`,
    },
  };
  const queryParameters = `utm_promoter=${trackingFeilds?.utm_promoter}&utm_source=${trackingFeilds?.utm_source}&utm_medium=${trackingFeilds?.utm_medium}&utm_campaign=${trackingFeilds?.utm_campaign}&utm_content=${trackingFeilds?.utm_content}&utm_term=${trackingFeilds?.utm_term}&pid=${trackingFeilds?.pid}&lookbook=${trackingFeilds?.lookbook}&product_eval=${product}&gclid=${trackingFeilds?.gclid}`;

  return {
    ...trackingFeilds,
    queryParameters,
    referrer: `${window.location.href}?${queryParameters}`,
  };
};

const fireGTMEvent = ({ category, action, label }) => {
  window?.dataLayer?.push?.({
    event: 'gaEvent',
    eventCategory: category,
    eventAction: action,
    eventLabel: label,
  });
};

const firePathFactoryConversion = ({ email }) => {
  if (window?.parent !== window.top) {
    const domain = window.location.hostname;
    const targetOrigin = domain.includes('tenable.com') ? `https://${domain}` : 'https://www.tenable.com';

    window?.parent?.postMessage?.({
      conversionUrl: document.location.href,
      referrer: document.referrer,
      email,
      lookbookExternalForm: true,
    }, targetOrigin);
  }
};

const fireConversionTracking = ({ product, appsArr = [], uuid }) => {
  fireGTMEvent({
    category: `${getTrackingName(product)} Eval`,
    action: 'Success',
    label: `${uuid || Date.now()} ${appsArr?.toString()}`,
  });

  const conversionLabels = {
    tio: 'DRj0CNGe2W0Q86vz_gM',
    vm: 'DRj0CNGe2W0Q86vz_gM',
    consec: 'ElPtCLWdqHQQ86vz_gM',
    was: '0Bi5CIefqHQQ86vz_gM',
    nessus: 'W3xFCK2UzQkQ86vz_gM',
    expert: 'W3xFCK2UzQkQ86vz_gM',
  };

  window?.google_trackConversion?.({
    google_conversion_id: 1071437299,
    google_conversion_label: conversionLabels[product],
    google_remarketing_only: false,
  });
};

const fireGaEvent = (event) => {
  const { category, label, action = `${window.location.origin}${window.location.pathname}` } = event;

  window?.ga?.('send', 'event', category, action, label);
};

export {
  getTrackingName,
  getTrackingFields,
  fireConversionTracking,
  firePathFactoryConversion,
  fireGTMEvent,
  fireGaEvent,
};
