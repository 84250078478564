const { boolean, mixed, object, string } = require('yup');

const schema = (messages = {}) => object().shape({
  first_name: string()
    .required(messages?.firstName?.required ?? 'First Name is required'),
  last_name: string()
    .required(messages?.lastName?.required ?? 'Last Name is required'),
  email: string()
    .email(messages?.email?.email ?? 'Invalid email address')
    .required(messages?.email?.required ?? 'Email address cannot be blank'),
  phone: string()
    .required(messages?.phone?.required ?? 'Phone is required'),
  country: string()
    .required(messages?.country?.required ?? 'Country is required'),
  region: string()
    .required(messages?.region?.required ?? 'Region is required'),
  city: string()
    .required(messages?.city?.required ?? 'City is required'),
  address: string()
    .required(messages?.address?.required ?? 'Address is required'),
  zip: string()
    .required(messages?.zip?.required ?? 'Zip is required'),
  title: string()
    .required(messages?.title?.required ?? 'Title is required'),
  organization: string()
    .when('product', {
      is: (product) => product === 'education',
      then: () => string()
        .required(messages?.organization?.education?.required ?? 'School/Training Program Name is required'),
    })
    .when('product', {
      is: (product) => product === 'charity',
      then: () => string()
        .required(messages?.organization?.charity?.required ?? 'Organization Name is required'),
    }),
  course: string()
    .when('product', {
      is: (product) => product === 'education',
      then: () => string()
        .required(messages?.course?.required ?? 'Course Title is required'),
    }),
  upload: mixed()
    .test({
      message: messages?.upload?.required ?? 'Upload PDF is required',
      test: (file) => file.length > 0,
    })
    .test({
      message: messages?.upload?.pdf ?? 'File must be a PDF',
      test: (files) => files.length > 0 && files.every((file) => (file.type === 'application/pdf')),
    }),
  product: string()
    .oneOf(['charity', 'education'])
    .required(messages?.product?.required ?? 'Product is required'),
  consentOptIn: boolean()
    .required(messages?.consentOptIn?.required ?? 'You must agree to the terms to proceed.')
    .oneOf([true], messages?.consentOptIn?.required ?? 'You must agree to the terms to proceed.'),
});

module.exports = schema;
