import { msg } from '@lit/localize';

const messages = () => ({
  first_name: {
    required: msg('First Name is required'),
  },
  last_name: {
    required: msg('Last Name is required'),
  },
  email: {
    email: msg('Invalid email address'),
    required: msg('Email address cannot be blank'),
  },
  phone: {
    required: msg('Phone is required'),
  },
  title: {
    required: msg('Title is required'),
  },
  company: {
    required: msg('Company is required'),
  },
  companySize: {
    required: msg('Company Size is required'),
  },
});

export default messages;
