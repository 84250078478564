import { msg } from '@lit/localize';

const messages = () => ({
  first_name: {
    string: msg('Invalid first name'),
    required: msg('First name is required'),
  },
  last_name: {
    string: msg('Invalid last name'),
    required: msg('Last name is required'),
  },
  email: {
    email: msg('Invalid email address'),
    required: msg('Email address cannot be blank'),
  },
});

export default messages;
