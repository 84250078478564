import {
  html,
  LitElement,
} from 'lit';
import styles from './styles';

class FormError extends LitElement {
  static styles = styles;

  static properties = {
    errors: {
      type: Array,
    },
  };

  constructor() {
    super();
    this.errors = [];
  }

  render() {
    if (!this.errors?.length) return html``;

    return html`
    <ul>
      ${this.errors.map((error) => html`<li>${error}</li>`)}
    </ul>
  `;
  }
}
customElements.define('tenable-form-error', FormError);
