import { LitElement, html } from 'lit';
import { msg, updateWhenLocaleChanges } from '@lit/localize';
import styles from './styles';
import '../../../../shared/components/icons/IconCheck';

class NessusExtensionConfirmation extends LitElement {
  static styles = styles;

  constructor() {
    super();
    updateWhenLocaleChanges(this);
  }

  render() {
    return html`
      <tenable-icon-check></tenable-icon-check>
      <h2>${msg('Thanks!')}</h2>
      <p>${msg('Your trial has been extended.')}</p>
    `;
  }
}

customElements.define('tenable-nessus-extension-confirmation', NessusExtensionConfirmation);
