import { html, LitElement } from 'lit';
import { msg, updateWhenLocaleChanges } from '@lit/localize';
import { styleMap } from 'lit/directives/style-map.js';
import styles from './styles';
import StepWizardContextConsumer from '../../context/step-wizard-context/StepWizardContextConsumer';

class StepWizardControls extends LitElement {
  static styles = styles;

  static properties = {
    style: {
      type: Object,
    },
    nextBtnStyle: {
      type: Object,
      attribute: 'next-btn-style',
    },
    previousBtnStyle: {
      type: Object,
      attribute: 'previous-btn-style',
    },
    nextBtnColor: {
      type: String,
      attribute: 'next-btn-color',
    },
    nextBtnSize: {
      type: String,
      attribute: 'next-btn-size',
    },
    previousBtnColor: {
      type: String,
      attribute: 'previous-btn-color',
    },
    previousBtnSize: {
      type: String,
      attribute: 'previous-btn-size',
    },
    nextLabel: {
      type: String,
      attribute: 'next-label',
    },
    previousLabel: {
      type: String,
      attribute: 'previous-label',
    },
    nextDisabled: {
      type: Boolean,
      attribute: 'next-disabled',
    },
    previousDisabled: {
      type: Boolean,
      attribute: 'previous-disabled',
    },
    nextStep: {
      type: Number,
      attribute: 'next-step',
    },
    previousStep: {
      type: Number,
      attribute: 'previous-step',
    },
    uncontrolled: {
      type: Boolean,
    },
  };

  constructor() {
    super();
    updateWhenLocaleChanges(this);

    this.style = {};
    this.previousBtnStyle = {};
    this.previousBtnColor = 'primary';
    this.previousBtnSize = '';
    this.nextBtnStyle = {};
    this.nextBtnColor = 'primary';
    this.nextBtnSize = '';
    this.nextStep = null;
    this.nextLabel = msg('Next');
    this.nextDisabled = false;
    this.previousLabel = msg('Back');
    this.previousDisabled = false;
    this.previousStep = null;
    this.uncontrolled = false;
  }

  context = StepWizardContextConsumer(this);

  handlePreviousClick(event) {
    if (this.previousStep !== null) {
      this.dispatchEvent(new CustomEvent('set-active-step', { composed: true, bubbles: true, detail: { step: this.previousStep } }));
      return;
    }

    if (this.uncontrolled) {
      this.dispatchEvent(new CustomEvent('previous-step', { composed: true, bubbles: true }));
      return;
    }

    this.dispatchEvent(
      new CustomEvent('previous-click', {
        detail: {
          srcEvent: event,
        },
        composed: true,
        bubbles: true,
      }),
    );
  }

  handleNextClick(event) {
    if (this.nextStep !== null) {
      this.dispatchEvent(new CustomEvent('set-active-step', { composed: true, bubbles: true, detail: { step: this.nextStep } }));
      return;
    }

    if (this.uncontrolled) {
      this.dispatchEvent(new CustomEvent('next-step', { composed: true, bubbles: true }));
      return;
    }

    this.dispatchEvent(
      new CustomEvent('next-click', {
        detail: {
          srcEvent: event,
        },
        composed: true,
        bubbles: true,
      }),
    );
  }

  render() {
    return html`
    <div class="step-wizard-controls" style="${styleMap(this.style)}">
      <tenable-button color="${this.previousBtnColor}" .style=${this.previousBtnStyle} ?disabled=${this.previousDisabled} size=${this.previousBtnSize} @click=${this.handlePreviousClick}>
        ${this.previousLabel}
      </tenable-button>

      <tenable-button color="${this.nextBtnColor}" .style=${this.nextBtnStyle} ?disabled=${this.nextDisabled} size=${this.nextBtnSize} @click=${this.handleNextClick}>
        ${this.nextLabel}
      </tenable-button>
    </div>
  `;
  }
}
customElements.define('tenable-step-wizard-controls', StepWizardControls);
