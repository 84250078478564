
    // Do not modify this file by hand!
    // Re-generate this file by running lit-localize

    import {html} from 'lit';
    

    /* eslint-disable no-irregular-whitespace */
    /* eslint-disable @typescript-eslint/no-explicit-any */

    export const templates = {
      'h0e60f0986075c2fd': html`Al solicitar esta licencia, Tenable podrá enviarle comunicaciones por correo electrónico sobre sus productos y servicios. Puede optar por no recibir estas comunicaciones en cualquier momento utilizando el enlace para darse de baja situado en el pie de página de los correos electrónicos que se le envían. También puede gestionar sus preferencias de correo electrónico de Tenable visitando la <a target="_blank" href="https://info.tenable.com/SubscriptionManagement.html">página de gestión de suscripciones</a>.`,
'h5d94556d32fdee9e': html`Tenable solo procesará sus datos personales de acuerdo con su <a target="_blank" href="https://www.tenable.com/privacy-policy">Política de privacidad</a>.`,
'h97a056af7968d75c': html`Al registrarse para esta licencia de prueba, Tenable podrá enviarle comunicaciones por correo electrónico sobre sus productos y servicios. Puede optar por no recibir estas comunicaciones en cualquier momento utilizando el enlace para darse de baja situado en el pie de página de los correos electrónicos que se le envían. También puede gestionar sus preferencias de correo electrónico de Tenable visitando la <a target="_blank" href="https://info.tenable.com/SubscriptionManagement.html">página de gestión de suscripciones</a>.`,
'hb2f7081d6bbb9ba0': html`Acepto los <a href="https://static.tenable.com/prod_docs/tenable_slas.html">acuerdos de licencia de software</a> de Tenable.`,
's09205907b5b56cda': `No`,
's0adb3f5e5e594e43': `El título del curso es obligatorio`,
's0f3489ff0bc2e14f': `Se debe cargar un PDF`,
's0fbf6dc6a1966408': `Siguiente`,
's12e486740e2e33fc': `El nombre de la organización es obligatorio`,
's150bac76e4f85b3c': `La región es obligatoria`,
's15361efa1eef6aee': `Comenzar`,
's1cdeecda0baf365e': `Lanzar`,
's1dda911063939e05': `Se extendió su prueba.`,
's1ed5c670ff346e93': `El comentario es obligatorio`,
's1f5a2beee27d45e7': `Es posible que un cliente haya avanzado entendiendo que tenía autorización de alguien de su organización para realizar el escaneo. Confirme haber verificado con las partes interesadas del negocio relacionadas si se autorizó este escaneo.`,
's24233c7c75e9ee19': `Tenga en cuenta que los comentarios se refieren únicamente a la documentación y no a los productos de Tenable.`,
's2468b69d10791c82': `Ciudad`,
's27707a7616f7f789': `El nombre es obligatorio`,
's27cc88818cc46ff6': `Estable`,
's2c57913ffd36d298': `Nombre`,
's2ea2118efa449d32': `Cargar PDF de la documentación o programa del curso`,
's2eb3c51d71b9f608': `Formato de código de activación no válido`,
's30699ee91122500e': `En breve nos pondremos en contacto con usted.`,
's31008bbe2c3076e5': `Tamaño de la empresa (empleados)`,
's32fc186d3b40fb7d': `Nombres de la organización`,
's39888fc990ed085e': `Debe consultar a las partes interesadas para continuar.`,
's3b8dc2a2dfe650e3': `Una vez que envíe este formulario, investigaremos cuál de nuestros clientes inició esta actividad de escaneo. Indique a continuación una dirección de correo electrónico para que podamos ponernos en contacto con usted una vez finalizada la investigación.`,
's404951d60833268e': `La URL de la página es obligatoria`,
's42cbd8dca939a9c7': `Consultar`,
's4362571c6638089a': `El nombre del programa de capacitación/escuela es obligatorio`,
's483a20681be190ae': `Título del curso`,
's4f2540795dacf546': `Supera el tamaño máximo de comentario`,
's5209870f843f5cd1': `Las IP de destino no pueden estar en blanco`,
's5855112288e12328': `El título es obligatorio`,
's5b7873343b9dc4eb': `¿En qué fecha/hora (en su zona horaria local) se detuvo el escaneo no autorizado?`,
's5d0cc12e0667faa7': `Nombre del programa de capacitación/escuela`,
's5ef6d725815017ce': `¿Qué IP fueron objeto del escaneo no autorizado?`,
's686306cdb839fb8d': `Enviando…`,
's6b986b4370cf95cf': `Proporcione la siguiente información para crear una cuenta e iniciar su prueba.`,
's6b9a88ee6c526aa4': `Las IP de origen no pueden estar en blanco`,
's6dcf1b198660d558': `¿Le ha ayudado esta página?`,
's6faf53a851ae1ef8': `Dirección de correo electrónico no válida`,
's745d8397b36781a7': `Para iniciar una prueba, proporcione la dirección de correo electrónico de su empresa.`,
's76b6c6ef5bc06202': `Disponibilidad general`,
's776925a2c81b7a63': `Correo electrónico profesional`,
's78df1fa22c512d7d': `Comentario (opcional)`,
's79df2775a8139811': `El acuerdo de términos es obligatorio`,
's7cb4e55c59663b1c': `Empresa`,
's80a0e23d9c7eea56': `La dirección es obligatoria`,
's81ec7a6847534d5e': `Volver atrás`,
's85e492de89c8ae2c': `El tamaño de la empresa es obligatorio`,
's8868de3e06abe230': `Correo electrónico no válido`,
's8a830d13f8d3298b': `Iniciar prueba`,
's912d50b0bb095241': `La dirección de correo electrónico no puede estar en blanco`,
's95a75a2392af5780': `Apellido`,
's99f110d27e30b289': `Título`,
's9cf7402fe920f7d1': `País`,
'sa21690c4800aeb69': `La ayuda de la página es obligatoria`,
'sa4343664d7b15d0c': `Cargar PDF del estado sin fines de lucro`,
'sa4cdfd30561e69a1': `Nombre no válido`,
'sa9c6d50e25c88911': `El apellido es obligatorio`,
'sae96e6ca5de9220c': `El código de activación no puede estar en blanco`,
'saf84ec86139d349e': `Comentario`,
'sb37fd819bd638708': `Deje un comentario`,
'sb3d4f79d9d8b71e5': `Enviar`,
'sb51270e19988f9bd': `Región`,
'sb7567af23b8f5703': `¡Gracias!`,
'sb7c9b2995d6869b1': `El apellido es obligatorio`,
'sb839a044bb672d70': `El país es obligatorio`,
'sbaf0ff2390822c90': `Su producto tiene ahora activada la evaluación de Nessus Expert.`,
'sbd66ac1792782ee6': `Para iniciar la prueba, descargue e instale Nessus.`,
'sbde039f6fe3847bf': `La fecha de inicio no puede estar en blanco`,
'sc16e00a7a8b2fde2': `Atrás`,
'sc46caeb9d1bc8fc6': `Envío fallido`,
'sc9446ac9849aa293': `Código postal`,
'scb489a1a173ac3f0': `Sí`,
'scf226d2c20f172c2': `El teléfono es obligatorio`,
'sd1ae08b28f9defa9': `La fecha de finalización no puede estar en blanco`,
'sd1f44f1a8bc20e67': `Correo electrónico`,
'sd33e190b8e41dc5f': `¿Cuáles fueron las IP de origen del escaneo no autorizado?`,
'sd3d0ca1bec5003d7': `La ciudad es obligatoria`,
'sda8ea7031a8a7d69': `El nombre es obligatorio`,
'sdc79ece8adeaa363': `Apellido no válido`,
'sdcf8c65961c70674': `Lo verifiqué con las partes interesadas del negocio y este escaneo no estaba autorizado.`,
'sde6ffc33e0ca1f9b': `Utilice la notación CIDR (es decir, 0.0.0.0/24 o 1.1.1.1/32)`,
'sdfb2b12b255de30c': `Copiado`,
'se032693d59c82e26': `Código de activación`,
'se1ad99292eaa4000': `Enviar solicitud`,
'se7c56a68f0ecf6e9': `Enviando...`,
'se8563c7e9107f391': `Dirección IP no válida`,
'sed7161a6bc9e01f6': `La dirección de correo electrónico es obligatoria`,
'sedabde95323d0cc2': `Correo electrónico no válido`,
'sf1af788523eecd55': `Descargar ahora`,
'sf1bc83d2509bafcf': `Teléfono`,
'sf1d7c751277ec33c': `Gracias por sus comentarios.`,
'sf4122b220926be97': `Versión`,
'sf820da2caa9be44b': `¿En qué fecha/hora (en su zona horaria local) comenzó el escaneo no autorizado?`,
'sf9286dfc7b09c4c3': `Dirección de correo electrónico prohibida/Dirección de correo electrónico no válida`,
'sfcdd8e672c75b713': `Dirección`,
'sfd76061a1ae701dd': `La empresa es obligatoria`,
'sff6467521565aa6d': `El producto es obligatorio`,
'sffe2914d4b7c91ee': `Acceso anticipado`,
's5f7a4982df278fbe': `Customer Name cannot be blank`,
's77d3ba42477d1e37': `Customer Email cannot be blank`,
's67da547a401b53a2': `Customer Organization cannot be blank`,
's4c672206f89e3a5a': `Output of OS Fingerprint plugin cannot be blank`,
's7e2003487616c0be': `Actual operating system and version running on asset cannot be blank`,
'sd2b571fd3bc03be2': `Product category cannot be blank`,
'sf1e5f5e4fdd3bba0': `Customer Name`,
's23a3125df912b8f9': `Customer Email`,
'se931b3bbed6e76fc': `Customer Organization`,
'scbf29ce484222325': ``,
's5d348d92f624a8d4': `Output of OS Fingerprint plugin`,
'sa425ce5a226eaca0': `Actual operating system and version running on asset`,
's5af6ae0f37cdcebc': `Product category`,
    };
  