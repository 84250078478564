import { css } from 'lit';

export default [
  css`
  tenable-tooltip {
    --tenable-tooltip-text-align: left;
    --tenable-tooltip-max-width: 500px;

    vertical-align: middle;
  }

  textarea {
    line-height: var(--tenable-textarea-line-height, normal);
    width: var(--tenable-textarea-width, 100%);
    border: var(--tenable-textarea-border, 1px solid #ccc);
    border-radius: var(--tenable-textarea-border-radius, 3px);
    padding: var(--tenable-textarea-padding, .5rem);
    display: var(--tenable-textarea-display, block);
    box-shadow: var(--tenable-textarea-box-shadow, inset 0 1px 2px #0000000d);
    resize: var(--tenable-textarea-resize, none);
    font-weight: var(--tenable-textarea-font-weight, 400);
    font-family: var(--tenable-textarea-font-family, 'Barlow', sans-serif);
    font-size: var(--tenable-textarea-font-size, 1rem);
    box-sizing: var(--tenable-textarea-box-sizing, border-box);
  }

  textarea:focus {
    border-color: var(--tenable-textarea-focus-border-color, #86b7fe);
    box-shadow: var(--tenable-textarea-focus-box-shadow, 0 0 0 .15rem rgba(13,110,253,.25));
    outline: 0;
  }

  textarea.invalid {
    border-color: var(--tenable-textarea-invalid-border-color, #e24301);
  }

  label {
    margin: var(--tenable-label-margin, 0 0 .25rem);
    display: var(--tenable-label-display, block);
    font-weight: var(--tenable-label-font-weight, 600);
    color: var(--tenable-label-color, #425363);
  }
  `,
];
