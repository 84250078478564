import { LitElement, html } from 'lit';
import { msg, updateWhenLocaleChanges } from '@lit/localize';
import styles from './styles';
import '../../../../shared/components/form/Form';
import '../../../../shared/components/form-group/FormGroup';
import '../../../../shared/components/form-feedback/FormFeedback';
import '../../../../shared/components/form-error/FormError';
import '../../../../shared/components/input/Input';
import '../../../../shared/components/button/Button';
import '../../../shared/consent-disclosure/ConsentDisclosure';
import FormController from '../../../../shared/controllers/FormController';
import schema from '../../../../../../schemas/ContactLookupSchema';
import messages from '../../../../../../schemas/ContactLookupSchema/messages';
import TrialApi from '../../../../shared/api/TrialApi';
import AppContextConsumer from '../../../../shared/context/app-context/AppContextConsumer';

class NessusContactLookup extends LitElement {
  static styles = styles;

  static properties = {
    product: {
      type: String,
    },
    partnerId: {
      type: String,
      attribute: 'partner-id',
      reflecct: true,
    },
    hideHeadings: {
      type: Boolean,
      attribute: 'hide-headings',
      reflecct: true,
    },
    hideLabels: {
      type: Boolean,
      attribute: 'hide-labels',
      reflecct: true,
    },
    floatingLabels: {
      type: Boolean,
      attribute: 'floating-labels',
      reflecct: true,
    },
    contact: {
      type: Object,
    },
    flexButtons: {
      type: Boolean,
      attribute: 'flex-buttons',
      reflecct: true,
    },
  };

  constructor() {
    super();
    updateWhenLocaleChanges(this);

    this.messages = messages();
    this.product = 'nessus';
    this.partnerId = '';
    this.hideHeadings = false;
    this.hideLabels = false;
    this.floatingLabels = false;
    this.flexButtons = false;
    this.contact = {};
  }

  context = AppContextConsumer(this);

  form = new FormController(this, { onSubmit: (params) => this.onSubmit(params) });

  connectedCallback() {
    super.connectedCallback();

    this.TrialApi = new TrialApi({ env: this.context.value?.env });
    this.form.schema = schema(this.messages);
    this.partnerId = this.context?.value?.partnerId ?? '';
    this.hideHeadings = this.context?.value?.hideHeadings ?? false;
    this.hideLabels = this.context?.value?.hideLabels ?? false;
    this.floatingLabels = this.context?.value?.floatingLabels ?? false;
    this.flexButtons = this.context?.value?.flexButtons ?? false;
  }

  async onSubmit({ values }) {
    const payload = { ...values, ...this.context?.value?.tracking ?? {} };
    this.dispatchEvent(new CustomEvent('update-contact', { composed: true, bubbles: true, detail: { value: payload } }));

    try {
      const { data } = await this.TrialApi.create(payload);

      // New trial created, show confirmation
      this.dispatchEvent(new CustomEvent('update-contact', { composed: true, bubbles: true, detail: { value: { ...data?.contact, ...payload } } }));
      this.dispatchEvent(new CustomEvent('set-active-step', { composed: true, bubbles: true, detail: { step: 2 } }));
      this.dispatchEvent(new CustomEvent('tracking-conversion', { composed: true, bubbles: true, detail: { product: this.product, appsArr: this.context?.value?.tracking?.apps } }));
    } catch (error) {
      const { cause } = error ?? {};

      // Contact does not exist, show new contact form
      if (cause?.status === 422) {
        this.dispatchEvent(new CustomEvent('set-active-step', { composed: true, bubbles: true, detail: { step: 1 } }));
        return;
      }

      const detail = { category: this.context?.value?.tracking?.gtm?.category, action: 'Server Fail', label: error };
      this.dispatchEvent(new CustomEvent('tracking-gtm', { composed: true, bubbles: true, detail }));

      throw error;
    }
  }

  render() {
    return html`
      ${!this.hideHeadings ? html`<h4>${msg('To start a trial, please provide your business email address.')}</h4>` : ''}
      <tenable-form .errors=${this.form.errors} .values=${this.form.values} @form-submitted=${(e) => this.form.submitHandler(e)}>
        <tenable-form-error .errors=${this.form.formErrors}></tenable-form-error>

        <tenable-input type="hidden" name="product" value="${this.product}" @input-change=${(e) => this.form.inputHandler(e)}></tenable-input>
        <tenable-input type="hidden" name="partnerId" value="${this.partnerId}" @input-change=${(e) => this.form.inputHandler(e)}></tenable-input>

        <div class="d-flex justify-content-between">
          <tenable-form-group class="mr-3 w-50">
            <tenable-input type="text" name="first_name" label="${msg('First Name')}" ?hideLabel=${this.hideLabels} label-type=${this.floatingLabels ? 'float' : 'before'} value="${this.contact?.first_name}" ?invalid=${this.form.errors.first_name} @input-change=${(e) => this.form.inputHandler(e)} @input-blur=${(e) => this.form.validateField(e)}></tenable-input>
            <tenable-form-feedback message=${this.form.errors.first_name}></tenable-form-feedback>
          </tenable-form-group>

          <tenable-form-group class="w-50">
            <tenable-input type="text" name="last_name" label="${msg('Last Name')}" ?hideLabel=${this.hideLabels} label-type=${this.floatingLabels ? 'float' : 'before'} value="${this.contact.last_name}" ?invalid=${this.form.errors.last_name} @input-change=${(e) => this.form.inputHandler(e)} @input-blur=${(e) => this.form.validateField(e)}></tenable-input>
            <tenable-form-feedback message=${this.form.errors.last_name}></tenable-form-feedback>
          </tenable-form-group>
        </div>

        <tenable-form-group>
          <tenable-input type="email" name="email" label="${msg('Business Email')}" ?hideLabel=${this.hideLabels} label-type=${this.floatingLabels ? 'float' : 'before'} value="${this.contact.email}" ?invalid=${this.form.errors.email} @input-change=${(e) => this.form.inputHandler(e)} @input-blur=${(e) => this.form.validateField(e)}></tenable-input>
          <tenable-form-feedback message=${this.form.errors.email}></tenable-form-feedback>
        </tenable-form-group>

        <tenable-form-group>
          <tenable-button class="${this.flexButtons ? 'd-flex justify-content-center' : ''}" type="submit" ?disabled=${this.form.isSubmitting}>
            ${this.form.isSubmitting ? msg('Submitting...') : msg('Get Started')}
          </tenable-button>
        </tenable-form-group>

        <tenable-consent-disclosure></tenable-consent-disclosure>
      </tenable-form>
    `;
  }
}

customElements.define('tenable-nessus-contact-lookup', NessusContactLookup);
