import { html, LitElement } from 'lit';
import { setLocaleFromHostname } from '../../../../../i18n/helpers';

class Localize extends LitElement {
  static properties = {
    locale: {
      type: String,
    },
  };

  constructor() {
    super();
    this.locale = '';
  }

  connectedCallback() {
    super.connectedCallback();
    setLocaleFromHostname(this.locale);
  }

  render() {
    return html`
      <slot></slot>
  `;
  }
}
customElements.define('tenable-localize', Localize);
