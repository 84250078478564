import { css } from 'lit';
import utilities from './utilities';

export default [
  utilities,
  css`
    :host {
      font-size: 100%;
      line-height: 1.5;
      color: var(--tenable-text-color, #425363);
    }

    h2 {
      color: var(--tenable-heading-color, #083f8a);
      margin: var(--tenable-heading-2-margin, 0 0 .25rem);
      font-size: var(--tenable-heading-2-font-size, 1.6rem);
      font-weight: var(--tenable-heading-2-font-weight, 600);
      line-height: var(--tenable-heading-2-line-height, 1.2);
    }

    p {
      color: var(--tenable-text-color, #425363);
      margin: 0 0 1rem;
    }

    small {
      font-size: var(--tenable-font-small, .75rem);
    }

    a,
    a:visited {
      color: var(--tenable-link-color, #0079dd);
      text-decoration: var(--tenable-link-decoration, none);
    }

    a:hover {
      text-decoration: underline;
    }
  `,
];
