import { css } from 'lit';
import sharedStyles from '../../../shared/styles';

export default [
  sharedStyles,
  css`
  p {
    line-height: var(--tenable-disclosure-line-height, 1.2);
    color: var(--tenable-disclosure-color, var(--tenable-text-color, #425363));
  }
  `,
];
