import { LitElement, html } from 'lit';
import { getDefaultEnv } from '../shared/utils/helpers';
import styles from '../shared/styles';
import '../shared/context/app-context/AppContextProvider';
import '../shared/components/localize/Localize';
import '../shared/components/tracking/Tracking';
import './components/os-signatures-container/OsSignaturesContainer';

class OsSignatures extends LitElement {
  static styles = styles;

  static properties = {
    env: {
      type: String,
      reflect: true,
    },
  };

  constructor() {
    super();
    this.env = '';
  }

  connectedCallback() {
    super.connectedCallback();

    const { env } = getDefaultEnv(this.env);

    this.env = env;
  }

  render() {
    return html`
      <tenable-app-provider .value=${{ env: this.env }}>
        <tenable-tracking>
          <tenable-localize>
            <tenable-os-signatures-container></tenable-os-signatures-container>
          </tenable-localize>
        </tenable-tracking>
      </tenable-app-provider>
    `;
  }
}

customElements.define('tenable-os-signatures', OsSignatures);
