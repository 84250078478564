
    // Do not modify this file by hand!
    // Re-generate this file by running lit-localize

    import {html} from 'lit';
    

    /* eslint-disable no-irregular-whitespace */
    /* eslint-disable @typescript-eslint/no-explicit-any */

    export const templates = {
      'h0e60f0986075c2fd': html`이 라이선스를 요청하면 Tenable에서 귀하에게 제품 및 서비스에 대해 이메일 커뮤니케이션을 보낼 수 있습니다. 받은 이메일의 바닥글에서 구독 취소 링크를 사용하여 언제든지 커뮤니케이션 수신을 옵트아웃할 수 있습니다. <a target="_blank" href="https://info.tenable.com/SubscriptionManagement.html">구독 관리 페이지</a>에서 Tenable 이메일 기본 설정을 관리할 수도 있습니다.`,
'h5d94556d32fdee9e': html`Tenable은 <a target="_blank" href="https://www.tenable.com/privacy-policy">개인 정보 보호 정책</a>에 따라서만 개인 데이터를 처리합니다.`,
'h97a056af7968d75c': html`이 평가판 라이선스에 등록하면 Tenable에서 귀하에게 제품 및 서비스에 대해 이메일 커뮤니케이션을 보낼 수 있습니다. 받은 이메일의 바닥글에서 구독 취소 링크를 사용하여 언제든지 커뮤니케이션 수신을 옵트아웃할 수 있습니다. <a target="_blank" href="https://info.tenable.com/SubscriptionManagement.html">구독 관리 페이지</a>를 방문하여 Tenable 이메일 기본 설정을 관리할 수도 있습니다.`,
'hb2f7081d6bbb9ba0': html`나는 Tenable의 <a href="https://static.tenable.com/prod_docs/tenable_slas.html">소프트웨어 라이선스 계약</a>에 동의합니다.`,
's09205907b5b56cda': `아니요`,
's0adb3f5e5e594e43': `과정 제목은 필수입니다.`,
's0f3489ff0bc2e14f': `PDF 업로드는 필수입니다.`,
's0fbf6dc6a1966408': `다음`,
's12e486740e2e33fc': `조직 이름은 필수입니다.`,
's150bac76e4f85b3c': `지역은 필수입니다.`,
's15361efa1eef6aee': `시작하기`,
's1cdeecda0baf365e': `릴리스`,
's1dda911063939e05': `평가판이 연장되었습니다.`,
's1ed5c670ff346e93': `의견은 필수입니다.`,
's1f5a2beee27d45e7': `고객이 조직의 누군가에게 권한을 부여받았다고 이해하여 스캔을 진행했을 수 있습니다. 담당 관계자에게 문의하여 승인된 스캔이었는지 확인하십시오.`,
's24233c7c75e9ee19': `피드백은 Tenable 제품이 아니라 문서화만을 위한 것입니다.`,
's2468b69d10791c82': `구/군/시`,
's27707a7616f7f789': `이름은 필수입니다.`,
's27cc88818cc46ff6': `안정화`,
's2c57913ffd36d298': `이름`,
's2ea2118efa449d32': `과정 커리큘럼 또는 문서의 PDF 업로드`,
's2eb3c51d71b9f608': `활성화 코드 형식이 유효하지 않습니다.`,
's30699ee91122500e': `요청에 대해 곧 연락드리겠습니다.`,
's31008bbe2c3076e5': `회사 규모(직원 수)`,
's32fc186d3b40fb7d': `조직 이름`,
's39888fc990ed085e': `계속 진행하려면 담당 관계자에게 문의하십시오.`,
's3b8dc2a2dfe650e3': `이 양식을 제출하면 어떤 고객이 이 스캔 활동을 시작했는지 조사가 진행됩니다. 조사가 완료된 후 연락드릴 수 있도록 아래에 이메일 주소를 입력하십시오.`,
's404951d60833268e': `페이지 URL은 필수입니다.`,
's42cbd8dca939a9c7': `확인`,
's4362571c6638089a': `학교/교육 프로그램 이름은 필수입니다.`,
's483a20681be190ae': `과정 제목`,
's4f2540795dacf546': `최대 의견 길이를 초과했습니다.`,
's5209870f843f5cd1': `타겟 IP는 비워 둘 수 없습니다.`,
's5855112288e12328': `직함은 필수입니다.`,
's5b7873343b9dc4eb': `어느 날짜/시간(현지 시간대)에 무단 스캔 트래픽이 종료되었습니까?`,
's5d0cc12e0667faa7': `학교/교육 프로그램 이름`,
's5ef6d725815017ce': `어떤 IP가 무단으로 스캔되었습니까?`,
's686306cdb839fb8d': `보내는 중...`,
's6b986b4370cf95cf': `계정을 만들고 평가판을 시작하려면 다음 정보를 입력하십시오.`,
's6b9a88ee6c526aa4': `소스 IP는 비워 둘 수 없습니다.`,
's6dcf1b198660d558': `이 페이지가 도움이 되었습니까?`,
's6faf53a851ae1ef8': `이메일 주소가 유효하지 않습니다.`,
's745d8397b36781a7': `평가판을 시작하려면 업무용 이메일 주소를 입력하십시오.`,
's76b6c6ef5bc06202': `정식 출시`,
's776925a2c81b7a63': `업무용 이메일`,
's78df1fa22c512d7d': `의견(선택 사항)`,
's79df2775a8139811': `약관 동의는 필수입니다.`,
's7cb4e55c59663b1c': `회사`,
's80a0e23d9c7eea56': `주소는 필수입니다.`,
's81ec7a6847534d5e': `돌아가기`,
's85e492de89c8ae2c': `회사 규모는 필수입니다.`,
's8868de3e06abe230': `이메일이 유효하지 않습니다.`,
's8a830d13f8d3298b': `평가판 시작`,
's912d50b0bb095241': `이메일 주소는 비워 둘 수 없습니다.`,
's95a75a2392af5780': `성`,
's99f110d27e30b289': `직함`,
's9cf7402fe920f7d1': `국가`,
'sa21690c4800aeb69': `페이지 도움 여부는 필수입니다.`,
'sa4343664d7b15d0c': `비영리 상태의 PDF 업로드`,
'sa4cdfd30561e69a1': `이름이 유효하지 않습니다.`,
'sa9c6d50e25c88911': `성은 필수입니다.`,
'sae96e6ca5de9220c': `활성화 코드는 비워 둘 수 없습니다.`,
'saf84ec86139d349e': `의견`,
'sb37fd819bd638708': `의견을 남겨주십시오.`,
'sb3d4f79d9d8b71e5': `제출`,
'sb51270e19988f9bd': `지역`,
'sb7567af23b8f5703': `감사합니다!`,
'sb7c9b2995d6869b1': `성은 필수입니다.`,
'sb839a044bb672d70': `국가는 필수입니다.`,
'sbaf0ff2390822c90': `이제 제품에서 Nessus Expert 평가를 사용할 수 있습니다.`,
'sbd66ac1792782ee6': `평가판을 시작하려면 Nessus를 다운로드하여 설치하십시오.`,
'sbde039f6fe3847bf': `시작 날짜는 비워 둘 수 없습니다.`,
'sc16e00a7a8b2fde2': `뒤로`,
'sc46caeb9d1bc8fc6': `제출 실패`,
'sc9446ac9849aa293': `우편 번호`,
'scb489a1a173ac3f0': `예`,
'scf226d2c20f172c2': `전화 번호는 필수입니다.`,
'sd1ae08b28f9defa9': `종료 날짜는 비워 둘 수 없습니다.`,
'sd1f44f1a8bc20e67': `이메일`,
'sd33e190b8e41dc5f': `무단 스캔이 발생한 소스 IP는 무엇입니까?`,
'sd3d0ca1bec5003d7': `구/군/시는 필수입니다.`,
'sda8ea7031a8a7d69': `이름은 필수입니다.`,
'sdc79ece8adeaa363': `성이 유효하지 않습니다.`,
'sdcf8c65961c70674': `담당 관계자에게 문의해 보니 승인된 스캔이 아니었습니다.`,
'sde6ffc33e0ca1f9b': `CIDR 표기법(예: 0.0.0.0/24 또는 1.1.1.1/32)으로 기재하십시오.`,
'sdfb2b12b255de30c': `복사됨`,
'se032693d59c82e26': `활성화 코드`,
'se1ad99292eaa4000': `요청 제출`,
'se7c56a68f0ecf6e9': `제출 중...`,
'se8563c7e9107f391': `IP 주소가 유효하지 않습니다.`,
'sed7161a6bc9e01f6': `이메일 주소는 필수입니다.`,
'sedabde95323d0cc2': `이메일이 유효하지 않습니다.`,
'sf1af788523eecd55': `지금 다운로드`,
'sf1bc83d2509bafcf': `전화 번호`,
'sf1d7c751277ec33c': `피드백을 보내 주셔서 감사합니다!`,
'sf4122b220926be97': `버전`,
'sf820da2caa9be44b': `어느 날짜/시간(현지 시간대)에 무단 스캔 트래픽이 시작되었습니까?`,
'sf9286dfc7b09c4c3': `이메일 주소가 차단되었거나 유효하지 않습니다.`,
'sfcdd8e672c75b713': `주소`,
'sfd76061a1ae701dd': `회사명은 필수입니다.`,
'sff6467521565aa6d': `제품은 필수입니다.`,
'sffe2914d4b7c91ee': `빠른 액세스`,
's5f7a4982df278fbe': `Customer Name cannot be blank`,
's77d3ba42477d1e37': `Customer Email cannot be blank`,
's67da547a401b53a2': `Customer Organization cannot be blank`,
's4c672206f89e3a5a': `Output of OS Fingerprint plugin cannot be blank`,
's7e2003487616c0be': `Actual operating system and version running on asset cannot be blank`,
'sd2b571fd3bc03be2': `Product category cannot be blank`,
'sf1e5f5e4fdd3bba0': `Customer Name`,
's23a3125df912b8f9': `Customer Email`,
'se931b3bbed6e76fc': `Customer Organization`,
'scbf29ce484222325': ``,
's5d348d92f624a8d4': `Output of OS Fingerprint plugin`,
'sa425ce5a226eaca0': `Actual operating system and version running on asset`,
's5af6ae0f37cdcebc': `Product category`,
    };
  