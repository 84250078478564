import { LitElement, html } from 'lit';
import { msg, updateWhenLocaleChanges } from '@lit/localize';
import styles from './styles';
import '../../../../shared/components/icons/IconCheck';
import '../../../../shared/components/button/Button';

class NessusConfirmation extends LitElement {
  static styles = styles;

  static properties = {
    product: {
      type: String,
    },
  };

  constructor() {
    super();
    updateWhenLocaleChanges(this);

    this.product = 'nessus';
  }

  render() {
    return html`
      <p>
        <tenable-icon-check color="#00F0FF"></tenable-icon-check>
      </p>
      <h2 class="mb-4">${msg('Thanks! To start your trial, download and install Nessus.')}</h2>
      <p>
        <a href="https://www.tenable.com/downloads/nessus?utm_source=nessus-trial-thank-you-update">
          <tenable-button color="primary" .style=${{ width: 'auto' }}>
            ${msg('Download Now')}
          </tenable-button>
        </a>
      </p>
    `;
  }
}

customElements.define('tenable-nessus-confirmation', NessusConfirmation);
