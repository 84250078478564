import { html, LitElement } from 'lit';
import styles from './styles';
import '../copy-button/CopyButton';

class Code extends LitElement {
  static styles = styles;

  static properties = {
    code: {
      type: String,
    },
  };

  constructor() {
    super();

    this.code = '';
  }

  render() {
    return html`
      <div class="code-block">
        <pre><code>${this.code}</code></pre>
        <span class="copy-button">
          <tenable-copy-button data="${this.code}"></tenable-copy-button>
        </span>
      </div>
    `;
  }
}
customElements.define('tenable-code', Code);
