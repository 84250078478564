import { getEnvVars } from '../utils/helpers';

class BaseApi {
  constructor(config) {
    const { baseUrl, environment } = getEnvVars(config?.env);
    this.baseUrl = baseUrl;
    this.environment = environment;
  }

  async parseResponse(response) {
    let data = {};

    try {
      data = await response.json();
    } catch (error) {
      console.error('Error parsing response', { error });
    }

    if (!response.ok) {
      const message = data?.message ?? response?.statusText ?? response;
      const cause = { status: response?.status, statusText: response?.statusText, data };
      throw new Error(message, { cause });
    }

    return { data };
  }
}

export default BaseApi;
