import { LitElement, html } from 'lit';
import { msg, updateWhenLocaleChanges } from '@lit/localize';
import styles from './styles';
import '../../../../shared/components/form/Form';
import '../../../../shared/components/form-group/FormGroup';
import '../../../../shared/components/form-feedback/FormFeedback';
import '../../../../shared/components/form-error/FormError';
import '../../../../shared/components/input/Input';
import '../../../../shared/components/button/Button';
import FormController from '../../../../shared/controllers/FormController';
import schema from '../../../../../../schemas/NessusProToExpertSchema';
import messages from '../../../../../../schemas/NessusProToExpertSchema/messages';
import NessusExpertApi from '../../../../shared/api/NessusExpertApi';
import AppContextConsumer from '../../../../shared/context/app-context/AppContextConsumer';

class ProToExpertForm extends LitElement {
  static styles = styles;

  static properties = {
    lengthInDays: {
      type: Number,
      attribute: 'length-in-days',
    },
  };

  constructor() {
    super();
    updateWhenLocaleChanges(this);

    this.lengthInDays = 7;
    this.messages = messages();
  }

  context = AppContextConsumer(this);

  form = new FormController(this, { onSubmit: (params) => this.onSubmit(params) });

  connectedCallback() {
    super.connectedCallback();

    this.NessusExpertApi = new NessusExpertApi({ env: this.context.value?.env });
    this.form.schema = schema(this.messages);
  }

  async onSubmit({ values }) {
    const payload = { ...values, ...this.context?.value?.tracking ?? {} };

    try {
      await this.NessusExpertApi.upgrade(payload);
      this.dispatchEvent(new CustomEvent('tracking-conversion', { composed: true, bubbles: true, detail: payload }));
    } catch (error) {
      const detail = { category: this.context?.value?.tracking?.gtm?.category, action: 'Server Fail', label: error };
      this.dispatchEvent(new CustomEvent('tracking-gtm', { composed: true, bubbles: true, detail }));

      throw error;
    }

    this.dispatchEvent(new CustomEvent('next-step', { composed: true, bubbles: true }));
  }

  render() {
    return html`
      <tenable-form .errors=${this.form.errors} .values=${this.form.values} @form-submitted=${(e) => this.form.submitHandler(e)}>
        <tenable-form-error .errors=${this.form.formErrors}></tenable-form-error>
        <tenable-input type="hidden" name="length_in_days" value=${this.lengthInDays} @input-change=${(e) => this.form.inputHandler(e)}></tenable-input>

        <tenable-form-group>
          <tenable-input type="email" name="email" label="${msg('Email')}" ?invalid=${this.form.errors.email} @input-change=${(e) => this.form.inputHandler(e)} @input-blur=${(e) => this.form.validateField(e)}></tenable-input>
          <tenable-form-feedback message=${this.form.errors.email}></tenable-form-feedback>
        </tenable-form-group>

        <tenable-form-group>
          <tenable-input type="text" name="code" label="${msg('Activation Code')}" ?invalid=${this.form.errors.code} @input-change=${(e) => this.form.inputHandler(e)} @input-blur=${(e) => this.form.validateField(e)}></tenable-input>
          <tenable-form-feedback message=${this.form.errors.code}></tenable-form-feedback>
        </tenable-form-group>

        <tenable-form-group>
          <tenable-button type="submit" color="primary" ?disabled=${this.form.isSubmitting}>
            ${this.form.isSubmitting ? msg('Submitting...') : msg('Get Started')}
          </tenable-button>
        </tenable-form-group>
      </tenable-form>
    `;
  }
}

customElements.define('tenable-nessus-pro-to-expert-form', ProToExpertForm);
