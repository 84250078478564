
    // Do not modify this file by hand!
    // Re-generate this file by running lit-localize

    import {html} from 'lit';
    

    /* eslint-disable no-irregular-whitespace */
    /* eslint-disable @typescript-eslint/no-explicit-any */

    export const templates = {
      'h0e60f0986075c2fd': html`La registrazione alla licenza consente a Tenable di inviare comunicazioni via email relative a prodotti e servizi. L'Utente può scegliere di non ricevere tali comunicazioni in qualsiasi momento utilizzando il link di annullamento incluso nel piè di pagina delle email. È inoltre possibile gestire le preferenze email di Tenable visitando la <a target="_blank" href="https://info.tenable.com/SubscriptionManagement.html">pagina di gestione delle sottoscrizioni</a>.`,
'h5d94556d32fdee9e': html`Tenable elaborerà i dati personali dell'Utente solamente in conformità all'<a target="_blank" href="https://www.tenable.com/privacy-policy">Informativa sulla privacy</a>.`,
'h97a056af7968d75c': html`La registrazione alla licenza di prova consente a Tenable di inviare comunicazioni via email relative a prodotti e servizi. L'Utente può scegliere di non ricevere tali comunicazioni in qualsiasi momento utilizzando il link di annullamento incluso nel piè di pagina delle email. È inoltre possibile gestire le preferenze email di Tenable visitando la <a target="_blank" href="https://info.tenable.com/SubscriptionManagement.html">pagina di gestione delle sottoscrizioni</a>.`,
'hb2f7081d6bbb9ba0': html`Accetto gli <a href="https://static.tenable.com/prod_docs/tenable_slas.html">accordi di licenza software</a> di Tenable.`,
's09205907b5b56cda': `No`,
's0adb3f5e5e594e43': `Il titolo del corso è obbligatorio`,
's0f3489ff0bc2e14f': `Il caricamento PDF è obbligatorio`,
's0fbf6dc6a1966408': `Avanti`,
's12e486740e2e33fc': `Il nome dell'organizzazione è obbligatorio`,
's150bac76e4f85b3c': `La regione è obbligatoria`,
's15361efa1eef6aee': `Inizia`,
's1cdeecda0baf365e': `Release`,
's1dda911063939e05': `La tua prova è stata prolungata.`,
's1ed5c670ff346e93': `Il commento è obbligatorio`,
's1f5a2beee27d45e7': `Probabilmente un cliente riteneva di avere l'autorizzazione da parte di qualcuno dell'organizzazione a eseguire la scansione. Conferma di aver verificato con le relative parti interessate se la scansione fosse autorizzata.`,
's24233c7c75e9ee19': `Tieni presente che il feedback riguarda solo la documentazione e non i prodotti Tenable.`,
's2468b69d10791c82': `Città`,
's27707a7616f7f789': `Il nome è obbligatorio`,
's27cc88818cc46ff6': `Stable`,
's2c57913ffd36d298': `Nome`,
's2ea2118efa449d32': `Caricare PDF Curriculum del corso o Documentazione`,
's2eb3c51d71b9f608': `Formato codice di attivazione non valido`,
's30699ee91122500e': `Ti contatteremo al più presto per esaminare la tua richiesta.`,
's31008bbe2c3076e5': `Dimensioni azienda (dipendenti)`,
's32fc186d3b40fb7d': `Nome dell'organizzazione`,
's39888fc990ed085e': `Per procedere è necessario verificare con le parti interessate.`,
's3b8dc2a2dfe650e3': `Una volta inviato il modulo, indagheremo per scoprire quale cliente ha avviato l'attività di scansione. Fornisci un indirizzo email per poter essere contattati al termine dell'indagine.`,
's404951d60833268e': `L'URL della pagina è obbligatorio`,
's42cbd8dca939a9c7': `Controlla`,
's4362571c6638089a': `Il nome del programma di scuola/formazione è obbligatorio`,
's483a20681be190ae': `Titolo del corso`,
's4f2540795dacf546': `Supera la dimensione di commento massima`,
's5209870f843f5cd1': `Gli IP di destinazione non possono essere vuoti`,
's5855112288e12328': `Il titolo è obbligatorio`,
's5b7873343b9dc4eb': `Data/ora (nel fuso orario locale) di interruzione del traffico di scansione non autorizzato?`,
's5d0cc12e0667faa7': `Nome del programma di scuola/formazione`,
's5ef6d725815017ce': `Quali IP sono stati presi di mira nella scansione non autorizzata?`,
's686306cdb839fb8d': `Invio...`,
's6b986b4370cf95cf': `Inserisci le seguenti informazioni per creare un account e iniziare la prova.`,
's6b9a88ee6c526aa4': `Gli IP sorgente non possono essere vuoti`,
's6dcf1b198660d558': `Questa pagina ti è stata di aiuto?`,
's6faf53a851ae1ef8': `Indirizzo email non valido`,
's745d8397b36781a7': `Per iniziare una prova, fornisci l'indirizzo email aziendale.`,
's76b6c6ef5bc06202': `Disponibilità generale`,
's776925a2c81b7a63': `Email aziendale`,
's78df1fa22c512d7d': `Commento (facoltativo)`,
's79df2775a8139811': `Le condizioni di contratto sono obbligatorie`,
's7cb4e55c59663b1c': `Azienda`,
's80a0e23d9c7eea56': `L'indirizzo è obbligatorio`,
's81ec7a6847534d5e': `Torna indietro`,
's85e492de89c8ae2c': `Le dimensioni azienda sono obbligatorie`,
's8868de3e06abe230': `Email non valida`,
's8a830d13f8d3298b': `Inizia la prova`,
's912d50b0bb095241': `L'indirizzo email non può essere vuoto`,
's95a75a2392af5780': `Cognome`,
's99f110d27e30b289': `Titolo`,
's9cf7402fe920f7d1': `Paese`,
'sa21690c4800aeb69': `L'utilità della pagina è obbligatoria`,
'sa4343664d7b15d0c': `Carica PDF Stato di senza scopo di lucro`,
'sa4cdfd30561e69a1': `Nome non valido`,
'sa9c6d50e25c88911': `Il cognome è obbligatorio`,
'sae96e6ca5de9220c': `Il codice di attivazione non può essere vuoto`,
'saf84ec86139d349e': `Commento`,
'sb37fd819bd638708': `Lascia un commento...`,
'sb3d4f79d9d8b71e5': `Invia`,
'sb51270e19988f9bd': `Regione`,
'sb7567af23b8f5703': `Grazie!`,
'sb7c9b2995d6869b1': `Il cognome è obbligatorio`,
'sb839a044bb672d70': `Il paese è obbligatorio`,
'sbaf0ff2390822c90': `Il prodotto è ora abilitato alla valutazione di Nessus Expert.`,
'sbd66ac1792782ee6': `Per iniziare la prova, scarica e installa Nessus.`,
'sbde039f6fe3847bf': `La data di inizio non può essere vuota`,
'sc16e00a7a8b2fde2': `Indietro`,
'sc46caeb9d1bc8fc6': `Invio non riuscito`,
'sc9446ac9849aa293': `Codice postale`,
'scb489a1a173ac3f0': `Sì`,
'scf226d2c20f172c2': `Il telefono è obbligatorio`,
'sd1ae08b28f9defa9': `La data di fine non può essere vuota`,
'sd1f44f1a8bc20e67': `Email`,
'sd33e190b8e41dc5f': `Da quali IP sorgente proveniva la scansione non autorizzata?`,
'sd3d0ca1bec5003d7': `La città è obbligatoria`,
'sda8ea7031a8a7d69': `Il nome è obbligatorio`,
'sdc79ece8adeaa363': `Cognome non valido`,
'sdcf8c65961c70674': `Ho verificato con le relative parti interessate e questa scansione non è stata autorizzata.`,
'sde6ffc33e0ca1f9b': `Utilizza la notazione CIDR (ad esempio: 0.0.0.0/24 o 1.1.1.1/32)`,
'sdfb2b12b255de30c': `Copia riuscita`,
'se032693d59c82e26': `Codice di attivazione`,
'se1ad99292eaa4000': `Invia la richiesta`,
'se7c56a68f0ecf6e9': `Invio...`,
'se8563c7e9107f391': `Indirizzo IP non valido`,
'sed7161a6bc9e01f6': `L'indirizzo email è obbligatorio`,
'sedabde95323d0cc2': `Email non valida`,
'sf1af788523eecd55': `Scarica ora`,
'sf1bc83d2509bafcf': `Telefono`,
'sf1d7c751277ec33c': `Grazie per il tuo feedback!`,
'sf4122b220926be97': `Versione`,
'sf820da2caa9be44b': `Data/ora (nel fuso orario locale) di inizio del traffico di scansione non autorizzato?`,
'sf9286dfc7b09c4c3': `Indirizzo email non consentito o non valido`,
'sfcdd8e672c75b713': `Indirizzo`,
'sfd76061a1ae701dd': `L'azienda è obbligatoria`,
'sff6467521565aa6d': `Il prodotto è obbligatorio`,
'sffe2914d4b7c91ee': `Accesso prioritario`,
's5f7a4982df278fbe': `Customer Name cannot be blank`,
's77d3ba42477d1e37': `Customer Email cannot be blank`,
's67da547a401b53a2': `Customer Organization cannot be blank`,
's4c672206f89e3a5a': `Output of OS Fingerprint plugin cannot be blank`,
's7e2003487616c0be': `Actual operating system and version running on asset cannot be blank`,
'sd2b571fd3bc03be2': `Product category cannot be blank`,
'sf1e5f5e4fdd3bba0': `Customer Name`,
's23a3125df912b8f9': `Customer Email`,
'se931b3bbed6e76fc': `Customer Organization`,
'scbf29ce484222325': ``,
's5d348d92f624a8d4': `Output of OS Fingerprint plugin`,
'sa425ce5a226eaca0': `Actual operating system and version running on asset`,
's5af6ae0f37cdcebc': `Product category`,
    };
  