import { LitElement, html } from 'lit';
import styles from '../shared/styles';
import '../shared/context/app-context/AppContextProvider';
import './components/unauthorized-scan-report-form/UnauthorizedScanReportForm';
import './components/unauthorized-scan-report-confirmation/UnauthorizedScanReportConfirmation';
import '../shared/components/step-wizard/StepWizard';
import '../shared/components/localize/Localize';
import '../shared/components/tracking/Tracking';

class UnauthorizedScanReport extends LitElement {
  static styles = styles;

  static properties = {
    env: { type: String },
  };

  constructor() {
    super();
    this.env = '';
  }

  connectedCallback() {
    super.connectedCallback();
  }

  render() {
    return html`
      <tenable-app-provider .value=${{ env: this.env }}>
        <tenable-tracking>
          <tenable-localize>
            <tenable-step-wizard>
              <tenable-unauthorized-scan-report-form></tenable-unauthorized-scan-report-form>
              <tenable-unauthorized-scan-report-confirmation></tenable-unauthorized-scan-report-confirmation>
            </tenable-step-wizard>
          </tenable-localize>
        </tenable-tracking>
      </tenable-app-provider>
    `;
  }
}

customElements.define('tenable-unauthorized-scan-report', UnauthorizedScanReport);
