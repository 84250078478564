import { LitElement, html } from 'lit';
import './components/nessus-contact-lookup/NessusContactLookup';
import './components/nessus-new-contact/NessusNewContact';
import './components/nessus-confirmation/NessusConfirmation';
import '../../shared/components/step-wizard/StepWizard';
import AppContextConsumer from '../../shared/context/app-context/AppContextConsumer';

class Nessus extends LitElement {
  static properties = {
    contact: {
      type: Object,
      state: true,
    },
    product: {
      type: String,
      reflect: true,
    },
  };

  constructor() {
    super();

    this.contact = {};
    this.product = 'nessus';
  }

  context = AppContextConsumer(this);

  connectedCallback() {
    super.connectedCallback();

    this.product = this.context?.value?.product ?? 'nessus';
  }

  #updateContact(event) {
    this.contact = event?.detail?.value ?? null;
  }

  render() {
    return html`
      <tenable-step-wizard>
        <tenable-nessus-contact-lookup product=${this.product} .contact=${this.contact} @update-contact=${(e) => this.#updateContact(e)}></tenable-nessus-contact-lookup>
        <tenable-nessus-new-contact product=${this.product} .contact=${this.contact}></tenable-nessus-new-contact>
        <tenable-nessus-confirmation product=${this.product}></tenable-nessus-confirmation>
      </tenable-step-wizard>
    `;
  }
}

customElements.define('tenable-nessus', Nessus);
