import BaseApi from './BaseApi';

class NonprofitApi extends BaseApi {
  async create(data) {
    const url = `${this.baseUrl}/evaluations/api/v1/nonprofit`;

    const formData = new FormData();

    Object.keys(data).forEach((fieldName) => {
      if (fieldName === 'upload') {
        Array.prototype.forEach.call(data[fieldName], (file) => {
          formData.append(fieldName, file, file.name);
        });
      } else {
        formData.append(fieldName, data[fieldName]);
      }
    });

    const response = await fetch(url, {
      method: 'POST',
      headers: {},
      body: formData,
    });

    return this.parseResponse(response);
  }
}

export default NonprofitApi;
