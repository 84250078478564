import { html, LitElement } from 'lit';
import { ContextProvider } from '@lit-labs/context';
import appConext from './AppConext';

class AppContextProvider extends LitElement {
  static properties = {
    value: { type: Object },
  };

  constructor() {
    super();
    this.value = {};
  }

  provider = new ContextProvider(this, {
    context: appConext,
    initialValue: this.value,
  });

  connectedCallback() {
    super.connectedCallback();
    this.provider.setValue(this.value);
  }

  setContextValue(event) {
    const value = { ...this.value, ...event.detail };
    this.provider.setValue(value);
    this.value = value;
  }

  render() {
    return html`
    <div
      @set-app-context=${(e) => this.setContextValue(e)}
    >
      <slot></slot>
    </div>
  `;
  }
}
customElements.define('tenable-app-provider', AppContextProvider);
