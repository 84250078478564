const { object, string } = require('yup');

const schema = (messages = {}) => object().shape({
  name: string()
    .required(messages?.name?.required ?? 'Customer Name cannot be blank'),
  email: string()
    .email(messages?.email?.email ?? 'Invalid email address')
    .required(messages?.email?.required ?? 'Customer Email cannot be blank'),
  organization: string()
    .required(messages?.organization?.required ?? 'Customer Organization cannot be blank'),
  output: string()
    .required(messages?.output?.required ?? 'Output of OS Fingerprint plugin cannot be blank'),
  version: string()
    .required(messages?.version?.required ?? 'Actual operating system and version running on asset cannot be blank'),
  category: string()
    .required(messages?.category?.required ?? 'Product category cannot be blank'),
});

module.exports = schema;
