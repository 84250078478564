import { LitElement, html } from 'lit';
import styles from '../shared/styles';
import '../shared/context/app-context/AppContextProvider';
import './components/nonprofit-form/NonprofitForm';
import './components/nonprofit-confirmation/NonprofitConfirmation';
import '../shared/components/step-wizard/StepWizard';
import '../shared/components/localize/Localize';
import '../shared/components/tracking/Tracking';

class NessusNonprofit extends LitElement {
  static styles = styles;

  static properties = {
    env: { type: String },
    type: { type: String },
  };

  constructor() {
    super();
    this.env = '';
    this.type = '';
  }

  connectedCallback() {
    super.connectedCallback();
  }

  render() {
    return html`
      <tenable-app-provider .value=${{ env: this.env }}>
        <tenable-tracking>
          <tenable-localize>
            <tenable-step-wizard>
              <tenable-nonprofit-form type=${this.type}></tenable-nonprofit-form>
              <tenable-nonprofit-confirmation></tenable-nonprofit-confirmation>
            </tenable-step-wizard>
          </tenable-localize>
        </tenable-tracking>
      </tenable-app-provider>
    `;
  }
}

customElements.define('tenable-nonprofit', NessusNonprofit);
