
    // Do not modify this file by hand!
    // Re-generate this file by running lit-localize

    import {html} from 'lit';
    

    /* eslint-disable no-irregular-whitespace */
    /* eslint-disable @typescript-eslint/no-explicit-any */

    export const templates = {
      'h0e60f0986075c2fd': html`En demandant cette licence, vous acceptez que Tenable vous envoie des communications par e-mail concernant ses produits et services. Vous pouvez à tout moment refuser de recevoir ces communications en utilisant le lien de désinscription situé dans le pied de page des e-mails qui vous sont adressés. Vous pouvez également gérer vos préférences en matière d'e-mail Tenable sur la <a target="_blank" href="https://info.tenable.com/SubscriptionManagement.html">page de gestion de vos abonnements</a>.`,
'h5d94556d32fdee9e': html`Tenable ne traitera vos données personnelles qu'en accord avec sa <a target="_blank" href="https://www.tenable.com/privacy-policy">Politique de confidentialité</a>.`,
'h97a056af7968d75c': html`En vous inscrivant à cette licence d'essai, vous acceptez que Tenable vous envoie des communications par e-mail concernant ses produits et services. Vous pouvez à tout moment refuser de recevoir ces communications en utilisant le lien de désinscription situé dans le pied de page des e-mails qui vous sont adressés. Vous pouvez également gérer vos préférences en matière d'e-mail Tenable sur la <a target="_blank" href="https://info.tenable.com/SubscriptionManagement.html">page de gestion de vos abonnements</a>.`,
'hb2f7081d6bbb9ba0': html`J'accepte les <a href="https://static.tenable.com/prod_docs/tenable_slas.html">conditions de licence des logiciels</a> de Tenable`,
's09205907b5b56cda': `Non`,
's0adb3f5e5e594e43': `Veuillez indiquer l'intitulé du cours`,
's0f3489ff0bc2e14f': `Le téléchargement du PDF est obligatoire`,
's0fbf6dc6a1966408': `Suivant`,
's12e486740e2e33fc': `Veuillez indiquer un nom d'entreprise`,
's150bac76e4f85b3c': `Veuillez indiquer une région`,
's15361efa1eef6aee': `Démarrer`,
's1cdeecda0baf365e': `Mise en production`,
's1dda911063939e05': `Votre essai a été prolongé.`,
's1ed5c670ff346e93': `Veuillez remplir le champ de commentaire`,
's1f5a2beee27d45e7': `Un client peut avoir procédé au scan en pensant qu'il avait l'autorisation d'une personne de votre entreprise pour le faire. Veuillez confirmer que vous avez vérifié auprès des parties prenantes concernées si ce scan était autorisé.`,
's24233c7c75e9ee19': `Notez que les avis ne concernent que la documentation, et non les produits Tenable.`,
's2468b69d10791c82': `Ville`,
's27707a7616f7f789': `Veuillez indiquer un prénom`,
's27cc88818cc46ff6': `Stable`,
's2c57913ffd36d298': `Prénom`,
's2ea2118efa449d32': `Télécharger le PDF de la documentation ou du programme du cours`,
's2eb3c51d71b9f608': `Format de code d'activation non valide`,
's30699ee91122500e': `Nous vous contacterons bientôt pour répondre à votre requête.`,
's31008bbe2c3076e5': `Taille de l'entreprise (employés)`,
's32fc186d3b40fb7d': `Nom de l'entreprise`,
's39888fc990ed085e': `Vous devez vérifier auprès des parties prenantes concernées avant de poursuivre.`,
's3b8dc2a2dfe650e3': `Une fois que vous aurez soumis ce formulaire, nous chercherons à savoir lequel de nos clients est à l'origine de cette activité de scan. Veuillez indiquer ci-dessous une adresse e-mail sur laquelle nous pourrons vous contacter à l'issue de l'enquête.`,
's404951d60833268e': `Veuillez indiquer l'URL de la page`,
's42cbd8dca939a9c7': `Vérifier`,
's4362571c6638089a': `Veuillez indiquer le nom du programme de formation / de l'établissement scolaire`,
's483a20681be190ae': `Intitulé du cours`,
's4f2540795dacf546': `Dépasse la taille maximale pour un commentaire`,
's5209870f843f5cd1': `Les adresses IP cibles doivent être renseignées`,
's5855112288e12328': `Veuillez indiquer une fonction`,
's5b7873343b9dc4eb': `Date/heure (dans votre fuseau horaire local) de fin du trafic de scan non autorisé ?`,
's5d0cc12e0667faa7': `Nom du programme de formation / de l'établissement scolaire`,
's5ef6d725815017ce': `Quelles adresses IP ont été ciblées lors du scan non autorisé ?`,
's686306cdb839fb8d': `Envoi en cours...`,
's6b986b4370cf95cf': `Veuillez fournir les informations suivantes pour créer un compte et commencer votre essai.`,
's6b9a88ee6c526aa4': `Les adresses IP d'origine doivent être renseignées`,
's6dcf1b198660d558': `Cette page vous a-t-elle aidé ?`,
's6faf53a851ae1ef8': `Adresse e-mail non valide`,
's745d8397b36781a7': `Pour commencer un essai, veuillez fournir votre adresse e-mail professionnelle.`,
's76b6c6ef5bc06202': `Disponibilité générale`,
's776925a2c81b7a63': `Adresse e-mail professionnelle`,
's78df1fa22c512d7d': `Commentaire (facultatif)`,
's79df2775a8139811': `L'acceptation des conditions est obligatoire`,
's7cb4e55c59663b1c': `Entreprise`,
's80a0e23d9c7eea56': `Veuillez indiquer une adresse`,
's81ec7a6847534d5e': `Retour`,
's85e492de89c8ae2c': `Veuillez indiquer la taille de l'entreprise`,
's8868de3e06abe230': `L'adresse e-mail n'est pas valide`,
's8a830d13f8d3298b': `Démarrer l'essai`,
's912d50b0bb095241': `Veuillez indiquer une adresse e-mail`,
's95a75a2392af5780': `Nom de famille`,
's99f110d27e30b289': `Fonction`,
's9cf7402fe920f7d1': `Pays`,
'sa21690c4800aeb69': `Veuillez indiquer si cette page vous a aidé`,
'sa4343664d7b15d0c': `Télécharger le PDF du statut d'organisation à but non lucratif`,
'sa4cdfd30561e69a1': `Prénom non valide`,
'sa9c6d50e25c88911': `Veuillez indiquer un nom de famille`,
'sae96e6ca5de9220c': `Veuillez indiquer un code d'activation`,
'saf84ec86139d349e': `Commentaire`,
'sb37fd819bd638708': `Laisser un commentaire...`,
'sb3d4f79d9d8b71e5': `Soumettre`,
'sb51270e19988f9bd': `Région`,
'sb7567af23b8f5703': `Merci !`,
'sb7c9b2995d6869b1': `Veuillez indiquer un nom de famille`,
'sb839a044bb672d70': `Veuillez indiquer un pays`,
'sbaf0ff2390822c90': `L'évaluation de Nessus Expert est désormais activée pour votre produit.`,
'sbd66ac1792782ee6': `Pour commencer votre essai, téléchargez et installez Nessus.`,
'sbde039f6fe3847bf': `La date de début doit être renseignée`,
'sc16e00a7a8b2fde2': `Retour`,
'sc46caeb9d1bc8fc6': `Échec de la soumission`,
'sc9446ac9849aa293': `Code postal`,
'scb489a1a173ac3f0': `Oui`,
'scf226d2c20f172c2': `Veuillez indiquer un numéro de téléphone`,
'sd1ae08b28f9defa9': `La date de fin doit être renseignée`,
'sd1f44f1a8bc20e67': `Adresse e-mail`,
'sd33e190b8e41dc5f': `Quelles étaient les adresses IP à l'origine du scan non autorisé ?`,
'sd3d0ca1bec5003d7': `Veuillez indiquer une ville`,
'sda8ea7031a8a7d69': `Veuillez indiquer un prénom`,
'sdc79ece8adeaa363': `Nom de famille non valide`,
'sdcf8c65961c70674': `J'ai vérifié auprès des parties prenantes concernées, et ce scan n'a pas été autorisé.`,
'sde6ffc33e0ca1f9b': `Veuillez utiliser la notation CIDR (par exemple, 0.0.0.0/24 ou 1.1.1.1/32).`,
'sdfb2b12b255de30c': `Copié !`,
'se032693d59c82e26': `Code d'activation`,
'se1ad99292eaa4000': `Soumettre une requête`,
'se7c56a68f0ecf6e9': `Soumission en cours...`,
'se8563c7e9107f391': `Adresse IP non valide`,
'sed7161a6bc9e01f6': `Veuillez indiquer une adresse e-mail`,
'sedabde95323d0cc2': `L'adresse e-mail n'est pas valide`,
'sf1af788523eecd55': `Télécharger maintenant`,
'sf1bc83d2509bafcf': `Téléphone`,
'sf1d7c751277ec33c': `Merci pour votre avis !`,
'sf4122b220926be97': `Version`,
'sf820da2caa9be44b': `Date/heure (dans votre fuseau horaire local) du début du trafic de scan non autorisé ?`,
'sf9286dfc7b09c4c3': `Adresse e-mail interdite ou non valide`,
'sfcdd8e672c75b713': `Adresse`,
'sfd76061a1ae701dd': `Veuillez indiquer un nom d'entreprise`,
'sff6467521565aa6d': `Veuillez indiquer le produit`,
'sffe2914d4b7c91ee': `Accès en avant-première`,
's5f7a4982df278fbe': `Customer Name cannot be blank`,
's77d3ba42477d1e37': `Customer Email cannot be blank`,
's67da547a401b53a2': `Customer Organization cannot be blank`,
's4c672206f89e3a5a': `Output of OS Fingerprint plugin cannot be blank`,
's7e2003487616c0be': `Actual operating system and version running on asset cannot be blank`,
'sd2b571fd3bc03be2': `Product category cannot be blank`,
'sf1e5f5e4fdd3bba0': `Customer Name`,
's23a3125df912b8f9': `Customer Email`,
'se931b3bbed6e76fc': `Customer Organization`,
'scbf29ce484222325': ``,
's5d348d92f624a8d4': `Output of OS Fingerprint plugin`,
'sa425ce5a226eaca0': `Actual operating system and version running on asset`,
's5af6ae0f37cdcebc': `Product category`,
    };
  