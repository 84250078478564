import { LitElement, html } from 'lit';
import { getDefaultEnv } from '../shared/utils/helpers';
import styles from '../shared/styles';
import '../shared/context/app-context/AppContextProvider';
import './nessus';
import './nessus-extension';
import './nessus-pro-to-expert';
import '../shared/components/localize/Localize';
import '../shared/components/tracking/Tracking';

class TenableEvaluation extends LitElement {
  static styles = styles;

  static properties = {
    env: {
      type: String,
      reflect: true,
    },
    type: { type: String },
    locale: { type: String },
    partnerId: {
      type: String,
      attribute: 'partner-id',
    },
    hideHeadings: {
      type: Boolean,
      attribute: 'hide-headings',
    },
    hideLabels: {
      type: Boolean,
      attribute: 'hide-labels',
    },
    floatingLabels: {
      type: Boolean,
      attribute: 'floating-labels',
    },
    flexButtons: {
      type: Boolean,
      attribute: 'flex-buttons',
    },
  };

  constructor() {
    super();
    this.env = '';
    this.type = '';
    this.locale = '';
    this.partnerId = '';
    this.hideHeadings = false;
    this.hideLabels = false;
    this.floatingLabels = false;
    this.flexButtons = false;
  }

  connectedCallback() {
    super.connectedCallback();

    const { env } = getDefaultEnv(this.env);
    this.env = env;
  }

  render() {
    const context = {
      env: this.env,
      product: this.type,
      partnerId: this.partnerId,
      hideHeadings: this.hideHeadings,
      hideLabels: this.hideLabels,
      floatingLabels: this.floatingLabels,
      flexButtons: this.flexButtons,
    };

    return html`
      <tenable-app-provider .value=${context}>
        <tenable-tracking>
          <tenable-localize locale="${this.locale}">
            ${['nessus', 'expert'].includes(this.type) ? html`<tenable-nessus product="${this.type}"></tenable-nessus>` : html``}
            ${this.type === 'nessus-extension' ? html`<tenable-nessus-extension></tenable-nessus-extension>` : html``}
            ${this.type === 'nessus-extension-extended' ? html`<tenable-nessus-extension length-in-days="30"></tenable-nessus-extension>` : html``}
            ${this.type === 'nessus-pro-to-expert' ? html`<tenable-nessus-pro-to-expert length-in-days="30"></tenable-nessus-pro-to-expert>` : html``}
          </tenable-localize>
        </tenable-tracking>
      </tenable-app-provider>
    `;
  }
}

customElements.define('tenable-evaluation', TenableEvaluation);
