import {
  LitElement,
  html,
} from 'lit';
import { ifDefined } from 'lit/directives/if-defined.js';
import styles from './styles';

class Form extends LitElement {
  static styles = styles;

  static properties = {
    rel: { type: String },
    name: { type: String },
    label: { type: String },
    noValidate: { type: Boolean, reflect: true, attribute: 'no-validate' },
    autoComplete: { type: Boolean, reflect: true, attribute: 'auto-complete' },
    errors: { type: Object },
    values: { type: Object },
  };

  constructor() {
    super();
    this.rel = '';
    this.name = '';
    this.label = '';
    this.noValidate = false;
    this.autoComplete = true;
    this.errors = {};
    this.values = {};
  }

  connectedCallback() {
    super.connectedCallback();

    if (this.submitButton) {
      this.submitButton.addEventListener('click', this.handleSubmit);
    }
  }

  get submitButton() {
    return (
      this.querySelector("button[type='submit']")
      || this.querySelector("input[type='submit']")
    );
  }

  handleSubmit = (event) => {
    event.preventDefault();

    this.dispatchEvent(
      new CustomEvent('form-submitted', {
        detail: {
          srcEvent: event,
          errors: this.errors,
          values: this.values,
        },
        composed: true,
        bubbles: true,
      }),
    );
  };

  render() {
    return html`
      <form
        @submit=${this.handleSubmit}
        role="form"
        name=${ifDefined(this.name || undefined)}
        rel=${ifDefined(this.rel || undefined)}
        label=${ifDefined(this.label || undefined)}
        autocomplete=${ifDefined(this.autoComplete ? 'on' : 'off')}
        ?novalidate=${this.novalidate}
        @handle-submit=${this.handleSubmit}
      >
        <slot></slot>
      </form>
    `;
  }
}
customElements.define('tenable-form', Form);
