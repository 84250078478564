import { css } from 'lit';

export default [
  css`
    .step-wizard-controls {
      display: flex;
      justify-content: space-between;
    }
  `,
];
