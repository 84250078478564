import { LitElement, html } from 'lit';
import { updateWhenLocaleChanges } from '@lit/localize';
import styles from './styles';
import '../os-signatures-form/OsSignaturesForm';
import '../../../shared/components/step-wizard/StepWizard';
import '../os-signatures-confirmation/OsSignaturesConfirmation';

class OsSignaturesContainer extends LitElement {
  static styles = styles;

  constructor() {
    super();
    updateWhenLocaleChanges(this);
  }

  connectedCallback() {
    super.connectedCallback();
  }

  render() {
    return html`
      <div class="os-signatures-container">
        <tenable-step-wizard>
          <tenable-os-signatures-form></tenable-os-signatures-form>
          <tenable-os-signatures-confirmation></tenable-os-signatures-confirmation>
        </tenable-step-wizard>
      </div>
    `;
  }
}

customElements.define('tenable-os-signatures-container', OsSignaturesContainer);
