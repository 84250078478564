import { LitElement, html } from 'lit';
import { msg, updateWhenLocaleChanges } from '@lit/localize';
import styles from './styles';
import '../../../shared/components/form/Form';
import '../../../shared/components/form-feedback/FormFeedback';
import '../../../shared/components/form-error/FormError';
import '../../../shared/components/input/Input';
import '../../../shared/components/select/Select';
import '../../../shared/components/textarea/Textarea';
import '../../../shared/components/button/Button';
import '../../../shared/components/form-group/FormGroup';
import FormController from '../../../shared/controllers/FormController';
import schema from '../../../../../schemas/OsSignaturesSchema';
import messages from '../../../../../schemas/OsSignaturesSchema/messages';
import AppContextConsumer from '../../../shared/context/app-context/AppContextConsumer';
import OsSignaturesApi from '../../../shared/api/OsSignaturesApi';

class OsSignaturesForm extends LitElement {
  static styles = styles;

  static properties = {
    exampleOSFingerprint: {
      type: String,
    },
  };

  constructor() {
    super();
    updateWhenLocaleChanges(this);
    this.messages = messages();
    this.options = [
      { value: '', label: '', placeholder: true },
      { label: 'camera', value: 'camera' },
      { label: 'embedded', value: 'embedded' },
      { label: 'firewall', value: 'firewall' },
      { label: 'general-purpose', value: 'general-purpose' },
      { label: 'load-balancer', value: 'load-balancer' },
      { label: 'pbx', value: 'pbx' },
      { label: 'printer', value: 'printer' },
      { label: 'router', value: 'router' },
      { label: 'scada', value: 'scada' },
      { label: 'switch', value: 'switch' },
      { label: 'wireless-access-point', value: 'wireless-access-point' },
    ];

    this.exampleOSFingerprint = `Example:
SSH:!:SSH-2.0-OpenSSH
NTP:!:unknown
SinFP:
P1:B11113:F0x12:W4960:O0204ffff:M1240:
P2:B11113:F0x12:W4912:O0204ffff0402080affffffff4445414401030309:M1240:
P3:B00000:F0x00:W0:O0:M0
P4:190500_7_p=443R
HTTP:!:Server: Apache
SSLcert:!:i/CN:Aruba7008-RBLlO3B70vK/O:Aruba Networkss/CN:securelogin.arubanetworks.coms/O:Aruba Networks
e5231c551907d47e65e874f7e38980efbfd94b74
i/CN:Aruba7008-RBLlO3B70vK/O:Aruba Networkss/CN:securelogin.arubanetworks.coms/O:Aruba Networks
5231c551907d47e65e874f7e38980efbfd94b74`;
  }

  context = AppContextConsumer(this);

  form = new FormController(this, { onSubmit: (params) => this.onSubmit(params) });

  connectedCallback() {
    super.connectedCallback();
    this.OsSignaturesApi = new OsSignaturesApi({ env: this.context.value?.env });
    this.form.schema = schema(this.messages);
  }

  async onSubmit({ values }) {
    const payload = { ...values, ...this.context?.value?.tracking ?? {} };
    await this.OsSignaturesApi.create(payload);

    this.dispatchEvent(new CustomEvent('tracking-ga', { composed: true, bubbles: true, detail: { category: 'os-signatures', label: 'submit' } }));
    this.dispatchEvent(new CustomEvent('next-step', { composed: true, bubbles: true }));
  }

  render() {
    return html`
      <tenable-form .errors=${this.form.errors} .values=${this.form.values} @form-submitted=${(e) => this.form.submitHandler(e)}>
        <tenable-form-error .errors=${this.form.formErrors}></tenable-form-error>

        <tenable-form-group>
          <tenable-input type="text" name="name" label="${msg('Customer Name')}" ?invalid=${this.form.errors.name} @input-change=${(e) => this.form.inputHandler(e)} @input-blur=${(e) => this.form.validateField(e)}></tenable-input>
          <tenable-form-feedback message=${this.form.errors.name}></tenable-form-feedback>
        </tenable-form-group>

        <tenable-form-group>
          <tenable-input type="email" name="email" label="${msg('Customer Email')}" ?invalid=${this.form.errors.email} @input-change=${(e) => this.form.inputHandler(e)} @input-blur=${(e) => this.form.validateField(e)}></tenable-input>
          <tenable-form-feedback message=${this.form.errors.email}></tenable-form-feedback>
        </tenable-form-group>

        <tenable-form-group>
          <tenable-input type="text" name="organization" label="${msg('Customer Organization')}" ?invalid=${this.form.errors.organization} @input-change=${(e) => this.form.inputHandler(e)} @input-blur=${(e) => this.form.validateField(e)}></tenable-input>
          <tenable-form-feedback message=${this.form.errors.organization}></tenable-form-feedback>
        </tenable-form-group>

        <tenable-form-group>
          <tenable-textarea
            rows="5"
            name="output"
            placeholder="${msg('')}"
            label="${msg('Output of OS Fingerprint plugin')}"
            tooltip="${this.exampleOSFingerprint}"
            ?invalid=${this.form.errors.output}
            @input-change=${(e) => this.form.inputHandler(e)}
            @input-blur=${(e) => this.form.validateField(e)}></tenable-textarea>
          <tenable-form-feedback message=${this.form.errors.output}></tenable-form-feedback>
        </tenable-form-group>

        <tenable-form-group>
          <tenable-input type="text" name="version" label="${msg('Actual operating system and version running on asset')}" ?invalid=${this.form.errors.version} @input-change=${(e) => this.form.inputHandler(e)} @input-blur=${(e) => this.form.validateField(e)}></tenable-input>
          <tenable-form-feedback message=${this.form.errors.version}></tenable-form-feedback>
        </tenable-form-group>

        <tenable-form-group>
          <tenable-select
            name="category"
            label="${msg('Product category')}"
            ?invalid=${this.form.errors.category}
            @input-change=${(e) => this.form.inputHandler(e)}
            @input-blur=${(e) => this.form.validateField(e)}
            .options=${this.options}
          >
          </tenable-select>
          <tenable-form-feedback message=${this.form.errors.category}></tenable-form-feedback>
        </tenable-form-group>

        <tenable-form-group>
          <tenable-button type="submit" color="primary" ?disabled=${this.form.isSubmitting}>
            ${this.form.isSubmitting ? msg('Sending...') : msg('Submit')}
          </tenable-button>
        </tenable-form-group>
      </tenable-form>
    `;
  }
}

customElements.define('tenable-os-signatures-form', OsSignaturesForm);
