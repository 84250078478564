import { msg } from '@lit/localize';

const messages = () => ({
  ipTargets: {
    matches: msg('Invalid IP address'),
    required: msg('Targeted IPs cannot be blank'),
  },
  ipSource: {
    matches: msg('Invalid IP address'),
    required: msg('Source IPs cannot be blank'),
  },
  email: {
    email: msg('Invalid email address'),
    required: msg('Email address cannot be blank'),
    bannedDomain: msg('Banned email address / Invalid email address'),
  },
  startDate: {
    required: msg('Start Date cannot be blank'),
  },
  stopDate: {
    required: msg('Stop Date cannot be blank'),
  },
  stakeholderConfirmation: {
    required: msg('You must check with related stakeholders to proceed.'),
  },
});

export default messages;
