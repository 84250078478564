import {
  html,
  LitElement,
} from 'lit';
import styles from './styles';

class FormFeedback extends LitElement {
  static styles = styles;

  static properties = {
    message: {
      type: String,
    },
    hidden: {
      type: Boolean,
    },
  };

  constructor() {
    super();
    this.message = '';
    this.hidden = false;
  }

  render() {
    return (this.message && !this.hidden) && html`<div>${this.message}</div>`;
  }
}
customElements.define('tenable-form-feedback', FormFeedback);
