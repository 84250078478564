const { object, string, number } = require('yup');

const schema = (messages = {}) => object().shape({
  code: string()
    .matches(/^([a-zA-Z0-9]{4}-{1}){3,4}([a-zA-Z0-9]{4}){1}$/,
      { message: messages?.code?.matches ?? 'Invalid Activation Code format' })
    .required(messages?.code?.required ?? 'Activation Code cannot be blank'),
  email: string()
    .email(messages?.email?.email ?? 'Invalid email address')
    .required(messages?.email?.required ?? 'Email address cannot be blank'),
  length_in_days: number().default(7),
});

module.exports = schema;
