
    // Do not modify this file by hand!
    // Re-generate this file by running lit-localize

    import {html} from 'lit';
    

    /* eslint-disable no-irregular-whitespace */
    /* eslint-disable @typescript-eslint/no-explicit-any */

    export const templates = {
      'h0e60f0986075c2fd': html`بموجب طلب هذه الرخصة، قد ترسل لك Tenable مراسلات عبر البريد الإلكتروني بخصوص منتجاتها وخدماتها. ويمكنك إيقاف استلام هذه المراسلات في أي وقت باستخدام رابط إلغاء الاشتراك الموجود في تذييل رسائل البريد الإلكتروني المرسلة إليك. ويمكنك أيضًا إدارة تفضيلات البريد الإلكتروني الخاصة بشركة Tenable، من خلال زيارة <a target="_blank" href="https://info.tenable.com/SubscriptionManagement.html">صفحة إدارة الاشتراك</a>.`,
'h5d94556d32fdee9e': html`ستعالج Tenable بياناتك الشخصية فقط وفقًا لـ <a target="_blank" href="https://www.tenable.com/privacy-policy">سياسة الخصوصية</a> لديها.`,
'h97a056af7968d75c': html`من خلال التسجيل للحصول على رخصة الإصدار التجريبي هذه، قد ترسل Tenable لك مراسلات عبر البريد الإلكتروني بخصوص منتجاتها وخدماتها. ويمكنك إيقاف استلام هذه المراسلات في أي وقت باستخدام رابط إلغاء الاشتراك الموجود في تذييل رسائل البريد الإلكتروني المرسلة إليك. ويمكنك أيضًا إدارة تفضيلات البريد الإلكتروني الخاصة بشركة Tenable، من خلال زيارة <a target="_blank" href="https://info.tenable.com/SubscriptionManagement.html">صفحة إدارة الاشتراك</a>.`,
'hb2f7081d6bbb9ba0': html`أوافق على <a href="https://static.tenable.com/prod_docs/tenable_slas.html">اتفاقيات ترخيص البرامج</a> لدى Tenable`,
's09205907b5b56cda': `لا`,
's0adb3f5e5e594e43': `عنوان الدورة التدريبية مطلوب`,
's0f3489ff0bc2e14f': `تحميل ملف PDF مطلوب`,
's0fbf6dc6a1966408': `التالي`,
's12e486740e2e33fc': `اسم المؤسسة مطلوب`,
's150bac76e4f85b3c': `المنطقة مطلوبة`,
's15361efa1eef6aee': `بدء الاستخدام`,
's1cdeecda0baf365e': `الإصدار`,
's1dda911063939e05': `لقد تم تمديد إصدارك التجريبي.`,
's1ed5c670ff346e93': `التعليق مطلوب`,
's1f5a2beee27d45e7': `ربما يكون العميل قد تابع متصورًا أن لديه تصريحًا من شخص ما في مؤسستك لإجراء الفحص. يُرجى التأكيد على أنك قد تحققت من ذلك مع أصحاب المصلحة التجاريين ذوي الصلة؛ لتحديد ما إذا كان هذا الفحص مصرحًا به.`,
's24233c7c75e9ee19': `تجدر الإشارة إلى أن الملاحظات للوثائق فقط وليست لمنتجات Tenable.`,
's2468b69d10791c82': `المدينة`,
's27707a7616f7f789': `الاسم الأول مطلوب`,
's27cc88818cc46ff6': `مستقرة`,
's2c57913ffd36d298': `الاسم الأول`,
's2ea2118efa449d32': `تحميل ملف PDF لمنهج أو وثائق الدورة التدريبية`,
's2eb3c51d71b9f608': `تنسيق رمز التنشيط غير صالح`,
's30699ee91122500e': `سنتواصل معك بخصوص طلبك قريبًا.`,
's31008bbe2c3076e5': `حجم الشركة (الموظفون)`,
's32fc186d3b40fb7d': `اسم المؤسسة`,
's39888fc990ed085e': `يجب عليك التحقق مع أصحاب المصلحة ذوي الصلة للمتابعة.`,
's3b8dc2a2dfe650e3': `بمجرد إرسالك هذا النموذج، سنقوم بالتحقيق لتحديد عميلنا الذي بدأ نشاط الفحص هذا. يُرجى توفير عنوان البريد الإلكتروني أدناه؛ لكي نقوم بالمتابعة عليه بعد انتهاء التحقيق.`,
's404951d60833268e': `عنوان URL للصفحة مطلوب`,
's42cbd8dca939a9c7': `تحقق`,
's4362571c6638089a': `اسم المدرسة/برنامج التدريب مطلوب`,
's483a20681be190ae': `عنوان الدورة التدريبية`,
's4f2540795dacf546': `يتجاوز الحد الأقصى لحجم التعليق`,
's5209870f843f5cd1': `لا يمكن ترك عناوين IP المستهدفة فارغة`,
's5855112288e12328': `المسمى الوظيفي مطلوب`,
's5b7873343b9dc4eb': `ما التاريخ/الوقت (في منطقتك الزمنية المحلية) الذي توقفت فيه حركة بيانات الفحص غير المصرح به؟`,
's5d0cc12e0667faa7': `اسم المدرسة/برنامج التدريب`,
's5ef6d725815017ce': `ما عناوين IP التي تم استهدافها في الفحص غير المصرح به؟`,
's686306cdb839fb8d': `جارٍ الإرسال...`,
's6b986b4370cf95cf': `يُرجى تقديم المعلومات الآتية لإنشاء حساب وبدء استخدام إصدارك التجريبي.`,
's6b9a88ee6c526aa4': `لا يمكن ترك عناوين IP المصدر فارغة`,
's6dcf1b198660d558': `هل ساعدتك هذه الصفحة؟`,
's6faf53a851ae1ef8': `عنوان البريد الإلكتروني غير صالح`,
's745d8397b36781a7': `لبدء استخدام إصدار تجريبي، يُرجى تقديم عنوان البريد الإلكتروني الخاص بالعمل لديك.`,
's76b6c6ef5bc06202': `التوافر العام`,
's776925a2c81b7a63': `البريد الإلكتروني للعمل`,
's78df1fa22c512d7d': `تعليق (اختياري)`,
's79df2775a8139811': `اتفاقية الشروط مطلوبة`,
's7cb4e55c59663b1c': `الشركة`,
's80a0e23d9c7eea56': `العنوان مطلوب`,
's81ec7a6847534d5e': `رجوع`,
's85e492de89c8ae2c': `حجم الشركة مطلوب`,
's8868de3e06abe230': `البريد الإلكتروني غير صالح`,
's8a830d13f8d3298b': `بدء الإصدار التجريبي`,
's912d50b0bb095241': `لا يمكن ترك عنوان البريد الإلكتروني فارغًا`,
's95a75a2392af5780': `اسم العائلة`,
's99f110d27e30b289': `المسمى الوظيفي`,
's9cf7402fe920f7d1': `البلد`,
'sa21690c4800aeb69': `مساعدة الصفحة مطلوبة`,
'sa4343664d7b15d0c': `تحميل ملف PDF للحالة غير الربحية`,
'sa4cdfd30561e69a1': `الاسم الأول غير صالح`,
'sa9c6d50e25c88911': `اسم العائلة مطلوب`,
'sae96e6ca5de9220c': `لا يمكن ترك رمز التنشيط فارغًا`,
'saf84ec86139d349e': `تعليق`,
'sb37fd819bd638708': `اترك تعليقًا...`,
'sb3d4f79d9d8b71e5': `إرسال`,
'sb51270e19988f9bd': `المنطقة`,
'sb7567af23b8f5703': `شكرًا!`,
'sb7c9b2995d6869b1': `اسم العائلة مطلوب`,
'sb839a044bb672d70': `البلد مطلوب`,
'sbaf0ff2390822c90': `لقد تم تمكين تقييم Nessus Expert في منتجك الآن.`,
'sbde039f6fe3847bf': `لا يمكن ترك تاريخ البدء فارغًا`,
'sc16e00a7a8b2fde2': `رجوع`,
'sc46caeb9d1bc8fc6': `فشل الإرسال`,
'sc9446ac9849aa293': `الرمز البريدي`,
'scb489a1a173ac3f0': `نعم`,
'scf226d2c20f172c2': `الهاتف مطلوب`,
'sd1ae08b28f9defa9': `لا يمكن ترك تاريخ الإيقاف فارغًا`,
'sd1f44f1a8bc20e67': `البريد الإلكتروني`,
'sd33e190b8e41dc5f': `ما عناوين IP المصدر التي نشأ منها الفحص غير المصرح به؟`,
'sd3d0ca1bec5003d7': `المدينة مطلوبة`,
'sda8ea7031a8a7d69': `الاسم الأول مطلوب`,
'sdc79ece8adeaa363': `اسم العائلة غير صالح`,
'sdcf8c65961c70674': `لقد تحققت من أصحاب المصلة التجاريين ذوي الصلة، وهذا الفحص لم يكن مصرحًا به.`,
'sde6ffc33e0ca1f9b': `يُرجى استخدام ترميز CIDR (أي: 0.0.0.0/24 أو 1.1.1.1/32)`,
'sdfb2b12b255de30c': `تم النسخ!`,
'se032693d59c82e26': `رمز التنشيط`,
'se1ad99292eaa4000': `إرسال طلب`,
'se7c56a68f0ecf6e9': `جارٍ الإرسال...`,
'se8563c7e9107f391': `عنوان IP غير صالح`,
'sed7161a6bc9e01f6': `عنوان البريد الإلكتروني مطلوب`,
'sedabde95323d0cc2': `البريد الإلكتروني غير صالح`,
'sf1af788523eecd55': `تنزيل الآن`,
'sf1bc83d2509bafcf': `الهاتف`,
'sf1d7c751277ec33c': `شكرًا لك على تقديم ملاحظاتك!`,
'sf4122b220926be97': `النسخة`,
'sf820da2caa9be44b': `ما التاريخ/الوقت (في منطقتك الزمنية المحلية) الذي بدأت فيه حركة بيانات الفحص غير المصرح به؟`,
'sf9286dfc7b09c4c3': `عنوان البريد الإلكتروني محظور / عنوان البريد الإلكتروني غير صالح`,
'sfcdd8e672c75b713': `العنوان`,
'sfd76061a1ae701dd': `الشركة مطلوبة`,
'sff6467521565aa6d': `المنتج مطلوب`,
'sffe2914d4b7c91ee': `الوصول المبكر`,
'sbd66ac1792782ee6': `Thanks! To start your trial, download and install Nessus.`,
's5f7a4982df278fbe': `Customer Name cannot be blank`,
's77d3ba42477d1e37': `Customer Email cannot be blank`,
's67da547a401b53a2': `Customer Organization cannot be blank`,
's4c672206f89e3a5a': `Output of OS Fingerprint plugin cannot be blank`,
's7e2003487616c0be': `Actual operating system and version running on asset cannot be blank`,
'sd2b571fd3bc03be2': `Product category cannot be blank`,
'sf1e5f5e4fdd3bba0': `Customer Name`,
's23a3125df912b8f9': `Customer Email`,
'se931b3bbed6e76fc': `Customer Organization`,
'scbf29ce484222325': ``,
's5d348d92f624a8d4': `Output of OS Fingerprint plugin`,
'sa425ce5a226eaca0': `Actual operating system and version running on asset`,
's5af6ae0f37cdcebc': `Product category`,
    };
  