import BaseApi from './BaseApi';

class UnauthorizedScanReportApi extends BaseApi {
  async create(body) {
    const url = `${this.baseUrl}/evaluations/api/v1/unauthorized-scan-report`;

    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    });

    return this.parseResponse(response);
  }
}

export default UnauthorizedScanReportApi;
