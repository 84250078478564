import {
  html,
  LitElement,
} from 'lit';
import { styleMap } from 'lit/directives/style-map.js';
import styles from './styles';

class Button extends LitElement {
  static styles = styles;

  static properties = {
    color: {
      type: String,
      converter: (value) => (['primary', 'secondary', 'success', 'warning', 'link'].includes(value) ? `btn-${value}` : 'btn-primary'),
    },
    type: {
      type: String,
      converter: (value) => (['button', 'submit', 'reset'].includes(value) ? value : 'button'),
    },
    disabled: { type: Boolean },
    size: {
      type: String,
      converter: (value) => (['sm', 'lg'].includes(value) ? `btn-${value}` : ''),
    },
    style: {
      type: Object,
    },
  };

  constructor() {
    super();
    this.color = 'btn-primary';
    this.type = 'button';
    this.size = '';
    this.disabled = false;
    this.style = {};
  }

  handleClick(event) {
    const eventType = this.type === 'submit' ? 'handle-submit' : 'button-click';

    this.dispatchEvent(
      new CustomEvent(eventType, {
        detail: {
          srcEvent: event,
        },
        composed: true,
        bubbles: true,
      }),
    );
  }

  render() {
    return html`
    <button
      @click=${this.handleClick}
      type="${this.type}"
      class="${this.color} ${this.size}"
      ?disabled=${this.disabled}
      role="button"
      style="${styleMap(this.style)}"
    >
      <slot></slot>
    </button>
  `;
  }
}
customElements.define('tenable-button', Button);
