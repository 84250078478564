import { html, LitElement } from 'lit';
import styles from './styles';

class Tooltip extends LitElement {
  static styles = styles;

  static properties = {
    hidden: {
      type: Boolean,
    },
    message: {
      type: String,
    },
    direction: {
      type: String,
    },
  };

  constructor() {
    super();
    this.hidden = false;
    this.message = '';
    this.direction = 'left';
  }

  render() {
    return html`
    <span data-message=${this.message} class="tooltip-${this.direction}${this.hidden === false ? ' tooltip' : ''}">
      <slot></slot>
    </span>
  `;
  }
}
customElements.define('tenable-tooltip', Tooltip);
