import { msg } from '@lit/localize';

const messages = () => ({
  firstName: {
    required: msg('First Name is required'),
  },
  lastName: {
    required: msg('Last Name is required'),
  },
  email: {
    email: msg('Email is not valid'),
    required: msg('Email address is required'),
  },
  phone: {
    required: msg('Phone is required'),
  },
  country: {
    required: msg('Country is required'),
  },
  region: {
    required: msg('Region is required'),
  },
  city: {
    required: msg('City is required'),
  },
  address: {
    required: msg('Address is required'),
  },
  title: {
    required: msg('Title is required'),
  },
  organization: {
    charity: {
      required: msg('Organization Name is required'),
    },
    education: {
      required: msg('School/Training Program Name is required'),
    },
  },
  course: {
    required: msg('Course Title is required'),
  },
  product: {
    required: msg('Product is required'),
  },
  upload: {
    required: msg('Upload PDF is required'),
  },
  consentOptIn: {
    required: msg('Terms agreement is required'),
  },
});

export default messages;
