import { css } from 'lit';
import sharedStyles from '../../../shared/styles';

export default [
  sharedStyles,
  css`
    .os-signatures-container {
      text-align: left;
      margin-top: 3rem;
      margin-bottom: 1rem;
      font-size: 16px;
      color: #415465;
      font-weight: 400;
    }
  `,
];
