import { LitElement, html } from 'lit';
import './components/nessus-pro-to-expert-form/NessusProToExpertForm';
import './components/nessus-pro-to-expert-confirmation/NessusProToExpertConfirmation';
import '../../shared/components/step-wizard/StepWizard';

class NessusProToExpert extends LitElement {
  static properties = {
    lengthInDays: {
      type: Number,
      attribute: 'length-in-days',
    },
  };

  constructor() {
    super();
    this.lengthInDays = 7;
  }

  render() {
    return html`
      <tenable-step-wizard>
        <tenable-nessus-pro-to-expert-form length-in-days=${this.lengthInDays}></tenable-nessus-pro-to-expert-form>
        <tenable-nessus-pro-to-expert-confirmation></tenable-nessus-pro-to-expert-confirmation>
      </tenable-step-wizard>
    `;
  }
}

customElements.define('tenable-nessus-pro-to-expert', NessusProToExpert);
