
    // Do not modify this file by hand!
    // Re-generate this file by running lit-localize

    import {html} from 'lit';
    

    /* eslint-disable no-irregular-whitespace */
    /* eslint-disable @typescript-eslint/no-explicit-any */

    export const templates = {
      'h0e60f0986075c2fd': html`このトライアルライセンスにご登録されたお客様に対し、Tenable より、当社の製品およびサービスに関する電子メールをお送りすることがあります。配信された電子メールのフッターに記載されている配信停止リンクを使用して、いつでも電子メールの受信を停止できます。また、<a target="_blank" href="https://info.tenable.com/SubscriptionManagement.html">購読管理ページ</a>にアクセスして Tenable のメール設定を管理することもできます。`,
'h5d94556d32fdee9e': html`Tenable は、<a target="_blank" href="https://www.tenable.com/privacy-policy">プライバシーポリシー</a>に記載されている方法でのみ、お客様の個人情報を処理します。`,
'h97a056af7968d75c': html`このトライアルライセンスにご登録されたお客様に対し、Tenable より、当社の製品およびサービスに関する電子メールをお送りすることがあります。配信された電子メールのフッターに記載されている配信停止リンクを使用して、いつでも電子メールの受信を停止できます。また、<a target="_blank" href="https://info.tenable.com/SubscriptionManagement.html">購読管理ページ</a>にアクセスして Tenable のメール設定を管理することもできます。`,
'hb2f7081d6bbb9ba0': html`Tenable の<a href="https://static.tenable.com/prod_docs/tenable_slas.html">ソフトウェアライセンス規約</a>に同意します`,
's09205907b5b56cda': `いいえ`,
's0adb3f5e5e594e43': `コース名は必須です`,
's0f3489ff0bc2e14f': `PDF のアップロードは必須です`,
's0fbf6dc6a1966408': `次へ`,
's12e486740e2e33fc': `企業名は必須です`,
's150bac76e4f85b3c': `地域は必須です`,
's15361efa1eef6aee': `開始する`,
's1cdeecda0baf365e': `リリース`,
's1dda911063939e05': `トライアル期間が延長されました。`,
's1ed5c670ff346e93': `コメントは必須です`,
's1f5a2beee27d45e7': `お客様は、社内のどなたかからスキャン実行の許可を得たと認識して実行を開始した可能性があります。このスキャンが承認されたものであるかについて、関連するビジネスステークホルダーへの確認が済んでいることをご確認ください。`,
's24233c7c75e9ee19': `フィードバックはドキュメントのみを対象としており、Tenable 製品は対象外であることに注意してください。`,
's2468b69d10791c82': `都市名`,
's27707a7616f7f789': `名は必須です`,
's27cc88818cc46ff6': `安定版 (Stable)`,
's2c57913ffd36d298': `名`,
's2ea2118efa449d32': `コースのカリキュラムまたはドキュメントの PDF をアップロードする`,
's2eb3c51d71b9f608': `アクティベーションコードのフォーマットが無効です`,
's30699ee91122500e': `お客様のリクエストにつきまして、すぐにご連絡いたします。`,
's31008bbe2c3076e5': `企業規模 (従業員数)`,
's32fc186d3b40fb7d': `企業名`,
's39888fc990ed085e': `関係するステークホルダーに確認して進める必要があります。`,
's3b8dc2a2dfe650e3': `このフォームの送信後、Tenable はどのお客様がこのスキャンを開始したかを調査いたします。調査終了後のフォローアップのために、以下にメールアドレスをご記入ください。`,
's404951d60833268e': `ページ URL は必須です`,
's42cbd8dca939a9c7': `確認する`,
's4362571c6638089a': `学校名 / トレーニングプログラム名は必須です`,
's483a20681be190ae': `コース名`,
's4f2540795dacf546': `コメントの文字数が上限を超えています`,
's5209870f843f5cd1': `ターゲット IP アドレスを空欄にすることはできません`,
's5855112288e12328': `役職名は必須です`,
's5b7873343b9dc4eb': `不正なスキャンのトラフィックが停止した日時 (お客様のローカルタイムゾーン)`,
's5d0cc12e0667faa7': `学校名 / トレーニングプログラム名`,
's5ef6d725815017ce': `不正スキャンの対象となった IP アドレスをご記入ください`,
's686306cdb839fb8d': `送信中...`,
's6b986b4370cf95cf': `アカウントを作成し、トライアルを開始するには、以下の情報を入力してください。`,
's6b9a88ee6c526aa4': `ソース IP アドレスを空欄にすることはできません`,
's6dcf1b198660d558': `このページは役に立ちましたか?`,
's6faf53a851ae1ef8': `メールアドレスが無効です`,
's745d8397b36781a7': `トライアルを開始するには、ビジネス用のメールアドレスをご記入ください。`,
's76b6c6ef5bc06202': `一般提供`,
's776925a2c81b7a63': `メールアドレス (ビジネス用)`,
's78df1fa22c512d7d': `コメント (オプション)`,
's79df2775a8139811': `利用規約への同意は必須です`,
's7cb4e55c59663b1c': `企業名`,
's80a0e23d9c7eea56': `所在地は必須です`,
's81ec7a6847534d5e': `戻る`,
's85e492de89c8ae2c': `企業規模は必須です`,
's8868de3e06abe230': `メールアドレスが無効です`,
's8a830d13f8d3298b': `トライアルを開始する`,
's912d50b0bb095241': `メールアドレスを空欄にすることはできません`,
's95a75a2392af5780': `姓`,
's99f110d27e30b289': `役職名`,
's9cf7402fe920f7d1': `国`,
'sa21690c4800aeb69': `参考になるページは必須です`,
'sa4343664d7b15d0c': `非営利団体認定書の PDF をアップロードする`,
'sa4cdfd30561e69a1': `名が無効です`,
'sa9c6d50e25c88911': `姓は必須です`,
'sae96e6ca5de9220c': `アクティベーションコードを空欄にすることはできません`,
'saf84ec86139d349e': `コメント`,
'sb37fd819bd638708': `コメントを残す`,
'sb3d4f79d9d8b71e5': `送信`,
'sb51270e19988f9bd': `地域`,
'sb7567af23b8f5703': `ありがとうございました。`,
'sb7c9b2995d6869b1': `姓は必須です`,
'sb839a044bb672d70': `国は必須です`,
'sbaf0ff2390822c90': `お客様の製品で、Nessus Expert の評価が有効になりました。`,
'sbd66ac1792782ee6': `トライアルを開始するには、Nessus をダウンロードし、インストールしてください。`,
'sbde039f6fe3847bf': `開始日を空欄にすることはできません`,
'sc16e00a7a8b2fde2': `戻る`,
'sc46caeb9d1bc8fc6': `送信に失敗しました`,
'sc9446ac9849aa293': `郵便番号`,
'scb489a1a173ac3f0': `はい`,
'scf226d2c20f172c2': `電話番号は必須です`,
'sd1ae08b28f9defa9': `終了日を空欄にすることはできません`,
'sd1f44f1a8bc20e67': `メールアドレス`,
'sd33e190b8e41dc5f': `不正なスキャンが発生したソース IP アドレス`,
'sd3d0ca1bec5003d7': `都市名は必須です`,
'sda8ea7031a8a7d69': `名は必須です`,
'sdc79ece8adeaa363': `姓が無効です`,
'sdcf8c65961c70674': `関係するビジネスステークホルダーへの確認を行い、このスキャンが承認されていないことを確認しました。`,
'sde6ffc33e0ca1f9b': `CIDR 表記を使用してください (例: 0.0.0.0/24 または 1.1.1.1/32)`,
'sdfb2b12b255de30c': `承りました。`,
'se032693d59c82e26': `アクティベーションコード`,
'se1ad99292eaa4000': `リクエストの送信`,
'se7c56a68f0ecf6e9': `送信中...`,
'se8563c7e9107f391': `IP アドレスが無効です`,
'sed7161a6bc9e01f6': `メールアドレスは必須です`,
'sedabde95323d0cc2': `メールアドレスが無効です`,
'sf1af788523eecd55': `今すぐダウンロード`,
'sf1bc83d2509bafcf': `電話番号`,
'sf1d7c751277ec33c': `フィードバックありがとうございました。`,
'sf4122b220926be97': `バージョン`,
'sf820da2caa9be44b': `不正なスキャンのトラフィックが開始された日時 (お客様のローカルタイムゾーン)`,
'sf9286dfc7b09c4c3': `禁止されたメールアドレス / 無効なメールアドレスです`,
'sfcdd8e672c75b713': `所在地`,
'sfd76061a1ae701dd': `企業名は必須です`,
'sff6467521565aa6d': `製品名は必須です`,
'sffe2914d4b7c91ee': `早期アクセス`,
's5f7a4982df278fbe': `Customer Name cannot be blank`,
's77d3ba42477d1e37': `Customer Email cannot be blank`,
's67da547a401b53a2': `Customer Organization cannot be blank`,
's4c672206f89e3a5a': `Output of OS Fingerprint plugin cannot be blank`,
's7e2003487616c0be': `Actual operating system and version running on asset cannot be blank`,
'sd2b571fd3bc03be2': `Product category cannot be blank`,
'sf1e5f5e4fdd3bba0': `Customer Name`,
's23a3125df912b8f9': `Customer Email`,
'se931b3bbed6e76fc': `Customer Organization`,
'scbf29ce484222325': ``,
's5d348d92f624a8d4': `Output of OS Fingerprint plugin`,
'sa425ce5a226eaca0': `Actual operating system and version running on asset`,
's5af6ae0f37cdcebc': `Product category`,
    };
  