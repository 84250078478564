import { html, LitElement } from 'lit';
import { getTrackingFields, fireConversionTracking, fireGTMEvent, fireGaEvent } from '../../utils/tracking';
import AppContextConsumer from '../../context/app-context/AppContextConsumer';

class Tracking extends LitElement {
  context = AppContextConsumer(this);

  static properties = {
    tracking: {
      type: Object,
      state: true,
    },
  };

  constructor() {
    super();
    this.tracking = {};
  }

  setTrackingFields() {
    const trackingFields = getTrackingFields({ product: this.context.value?.product });

    this.dispatchEvent(
      new CustomEvent('set-app-context', {
        detail: {
          tracking: trackingFields,
        },
        composed: true,
        bubbles: true,
      }),
    );
    this.tracking = trackingFields;
  }

  conversionEvent(event) {
    const data = event?.detail ?? {};
    fireConversionTracking(data);
  }

  gtmEvent(event) {
    const data = event?.detail ?? {};
    fireGTMEvent(data);
  }

  gaEvent(event) {
    const data = event?.detail ?? {};
    fireGaEvent(data);
  }

  render() {
    return html`
    <div
      @tracking-conversion=${(e) => this.conversionEvent(e)}
      @tracking-gtm=${(e) => this.gtmEvent(e)}
      @tracking-ga=${(e) => this.gaEvent(e)}
    >
      <slot @slotchange=${this.setTrackingFields}></slot>
    </div>
  `;
  }
}
customElements.define('tenable-tracking', Tracking);
