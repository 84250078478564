
    // Do not modify this file by hand!
    // Re-generate this file by running lit-localize

    import {html} from 'lit';
    

    /* eslint-disable no-irregular-whitespace */
    /* eslint-disable @typescript-eslint/no-explicit-any */

    export const templates = {
      'h0e60f0986075c2fd': html`一旦您申請此授權，Tenable 可能會向您發送宣傳其產品和服務的電子郵件。您隨時可選擇不要再收到這些宣傳郵件，方法是點選位於這些電子郵件頁尾的取消訂閱連結。另一個方法是前往<a target="_blank" href="https://info.tenable.com/SubscriptionManagement.html">訂閱管理頁面</a>更改 Tenable 電子郵件喜好設定。`,
'h5d94556d32fdee9e': html`Tenable 一定會按照其<a target="_blank" href="https://www.tenable.com/privacy-policy">隱私權政策</a>的規定處理您的個人資料。`,
'h97a056af7968d75c': html`一旦您註冊此試用授權，Tenable 可能會向您發送宣傳其產品和服務的電子郵件。您隨時可選擇不要再收到這些宣傳郵件，方法是點選位於這些電子郵件頁尾的取消訂閱連結。另一個方法是前往<a target="_blank" href="https://info.tenable.com/SubscriptionManagement.html">訂閱管理頁面</a>更改 Tenable 電子郵件喜好設定。`,
'hb2f7081d6bbb9ba0': html`本人同意 Tenable 的<a href="https://static.tenable.com/prod_docs/tenable_slas.html">軟體授權協議</a>`,
's09205907b5b56cda': `沒有`,
's0adb3f5e5e594e43': `需輸入課程名稱`,
's0f3489ff0bc2e14f': `需上傳 PDF`,
's0fbf6dc6a1966408': `下一步`,
's12e486740e2e33fc': `需輸入企業名稱`,
's150bac76e4f85b3c': `需輸入區域`,
's15361efa1eef6aee': `開始`,
's1cdeecda0baf365e': `發行版次`,
's1dda911063939e05': `您的試用期已延長。`,
's1ed5c670ff346e93': `需填寫註解`,
's1f5a2beee27d45e7': `客戶可能已著手向貴公司內部請求授權此次掃描並且獲准。請確認您已知會業務相關負責人，確定此次掃描獲得授權核准。`,
's24233c7c75e9ee19': `請注意，請針對說明文件而非 Tenable 產品提出您的意見評語。`,
's2468b69d10791c82': `鄉/鎮/市/區`,
's27707a7616f7f789': `需輸入名字`,
's27cc88818cc46ff6': `穩定`,
's2c57913ffd36d298': `名字`,
's2ea2118efa449d32': `上傳 PDF 格式的課程或說明文件`,
's2eb3c51d71b9f608': `啟用代碼格式無效`,
's30699ee91122500e': `我們將盡快與您聯繫，洽談您請求的事宜。`,
's31008bbe2c3076e5': `公司規模 (員工人數)`,
's32fc186d3b40fb7d': `企業名稱`,
's39888fc990ed085e': `您必須跟相關人士確認後才能繼續。`,
's3b8dc2a2dfe650e3': `一旦您提交此表格，我們會調查是哪些客戶主動申請此掃描活動。請在下方輸入一個電子郵件地址，以便我們在調查完成後採取後續行動。`,
's404951d60833268e': `需輸入網頁 URL`,
's42cbd8dca939a9c7': `查看`,
's4362571c6638089a': `需輸入學校/培訓課程名稱`,
's483a20681be190ae': `課程名稱`,
's4f2540795dacf546': `註解長度超過上限`,
's5209870f843f5cd1': `目標 IP 不得空白`,
's5855112288e12328': `需輸入職稱`,
's5b7873343b9dc4eb': `非授權掃描流量停止的日期/時間 (以您當地的時區為準)？`,
's5d0cc12e0667faa7': `學校/培訓課程名稱`,
's5ef6d725815017ce': `哪些 IP 是本次非授權掃描的目標？`,
's686306cdb839fb8d': `發送中…`,
's6b986b4370cf95cf': `請輸入以下資訊以建立帳戶並開始試用。`,
's6b9a88ee6c526aa4': `來源 IP 不得空白`,
's6dcf1b198660d558': `這個頁面對您有幫助嗎？`,
's6faf53a851ae1ef8': `電子郵件地址無效`,
's745d8397b36781a7': `若要開始試用，請輸入您的公司電子郵件地址。`,
's76b6c6ef5bc06202': `全面推出`,
's776925a2c81b7a63': `公司電子郵件`,
's78df1fa22c512d7d': `註解 (選填)`,
's79df2775a8139811': `需簽訂協議條款`,
's7cb4e55c59663b1c': `公司`,
's80a0e23d9c7eea56': `需輸入地址`,
's81ec7a6847534d5e': `返回`,
's85e492de89c8ae2c': `需輸入公司規模`,
's8868de3e06abe230': `電子郵件無效`,
's8a830d13f8d3298b': `開始試用`,
's912d50b0bb095241': `電子郵件地址不得空白`,
's95a75a2392af5780': `姓氏`,
's99f110d27e30b289': `職稱`,
's9cf7402fe920f7d1': `國家`,
'sa21690c4800aeb69': `需參考實用頁面`,
'sa4343664d7b15d0c': `上傳 PDF 格式的非營利組織身分`,
'sa4cdfd30561e69a1': `名字無效`,
'sa9c6d50e25c88911': `需輸入姓氏`,
'sae96e6ca5de9220c': `啟用代碼不得空白`,
'saf84ec86139d349e': `註解`,
'sb37fd819bd638708': `發表註解…`,
'sb3d4f79d9d8b71e5': `提交`,
'sb51270e19988f9bd': `區域`,
'sb7567af23b8f5703': `謝謝！`,
'sb7c9b2995d6869b1': `需輸入姓氏`,
'sb839a044bb672d70': `需輸入國家`,
'sbaf0ff2390822c90': `您的產品現已啟用 Nessus Expert 評估。`,
'sbd66ac1792782ee6': `若要開始試用，請下載並安裝 Nessus。`,
'sbde039f6fe3847bf': `開始日期不得空白`,
'sc16e00a7a8b2fde2': `上一步`,
'sc46caeb9d1bc8fc6': `提交失敗`,
'sc9446ac9849aa293': `郵遞區號`,
'scb489a1a173ac3f0': `有`,
'scf226d2c20f172c2': `需輸入電話`,
'sd1ae08b28f9defa9': `停止日期不得空白`,
'sd1f44f1a8bc20e67': `電子郵件`,
'sd33e190b8e41dc5f': `非授權掃描的來源 IP 是什麼？`,
'sd3d0ca1bec5003d7': `需輸入鄉/鎮/市/區`,
'sda8ea7031a8a7d69': `需輸入名字`,
'sdc79ece8adeaa363': `姓氏無效`,
'sdcf8c65961c70674': `我已向業務相關負責人查證過，但發現此次掃描未獲授權核准。`,
'sde6ffc33e0ca1f9b': `請使用 CIDR 表示法 (即：0.0.0.0/24 或 1.1.1.1/32)`,
'sdfb2b12b255de30c': `已複製！`,
'se032693d59c82e26': `啟用代碼`,
'se1ad99292eaa4000': `提交請求`,
'se7c56a68f0ecf6e9': `提交中…`,
'se8563c7e9107f391': `IP 地址無效`,
'sed7161a6bc9e01f6': `需輸入電子郵件地址`,
'sedabde95323d0cc2': `電子郵件無效`,
'sf1af788523eecd55': `立即下載`,
'sf1bc83d2509bafcf': `電話`,
'sf1d7c751277ec33c': `感謝您的意見評語！`,
'sf4122b220926be97': `版本`,
'sf820da2caa9be44b': `非授權掃描流量開始的日期/時間 (以您當地的時區為準)？`,
'sf9286dfc7b09c4c3': `禁止使用此電子郵件地址/電子郵件地址無效`,
'sfcdd8e672c75b713': `地址`,
'sfd76061a1ae701dd': `需輸入公司名稱`,
'sff6467521565aa6d': `需輸入產品名稱`,
'sffe2914d4b7c91ee': `搶先體驗`,
's5f7a4982df278fbe': `Customer Name cannot be blank`,
's77d3ba42477d1e37': `Customer Email cannot be blank`,
's67da547a401b53a2': `Customer Organization cannot be blank`,
's4c672206f89e3a5a': `Output of OS Fingerprint plugin cannot be blank`,
's7e2003487616c0be': `Actual operating system and version running on asset cannot be blank`,
'sd2b571fd3bc03be2': `Product category cannot be blank`,
'sf1e5f5e4fdd3bba0': `Customer Name`,
's23a3125df912b8f9': `Customer Email`,
'se931b3bbed6e76fc': `Customer Organization`,
'scbf29ce484222325': ``,
's5d348d92f624a8d4': `Output of OS Fingerprint plugin`,
'sa425ce5a226eaca0': `Actual operating system and version running on asset`,
's5af6ae0f37cdcebc': `Product category`,
    };
  