import { LitElement, html } from 'lit';
import { msg, updateWhenLocaleChanges } from '@lit/localize';
import styles from './styles';
import '../../../shared/components/form/Form';
import '../../../shared/components/form-group/FormGroup';
import '../../../shared/components/form-feedback/FormFeedback';
import '../../../shared/components/form-error/FormError';
import '../../../shared/components/input/Input';
import '../../../shared/components/button/Button';
import '../../../shared/components/select/Select';
import countries from '../../../shared/components/select/CountrySelectOptions';
import FormController from '../../../shared/controllers/FormController';
import schema from '../../../../../schemas/NonprofitSchema';
import messages from '../../../../../schemas/NonprofitSchema/messages';
import NonprofitApi from '../../../shared/api/NonprofitApi';
import AppContextConsumer from '../../../shared/context/app-context/AppContextConsumer';

class NonprofitForm extends LitElement {
  static styles = styles;

  static properties = {
    type: {
      type: String,
    },
  };

  constructor() {
    super();
    updateWhenLocaleChanges(this);

    this.messages = messages();
  }

  context = AppContextConsumer(this);

  form = new FormController(this, {
    onSubmit: (params) => this.onSubmit(params),
  });

  connectedCallback() {
    super.connectedCallback();
    this.NonprofitApi = new NonprofitApi({ env: this.context.value?.env });
    this.form.schema = schema(this.messages);
  }

  async onSubmit({ values }) {
    const payload = { ...this.context?.value?.tracking ?? {}, ...values };

    try {
      await this.NonprofitApi.create(payload);
      this.dispatchEvent(new CustomEvent('tracking-conversion', { composed: true, bubbles: true, detail: payload }));
    } catch (error) {
      const detail = { category: this.context?.value?.tracking?.gtm?.category, action: 'Server Fail', label: error };
      this.dispatchEvent(new CustomEvent('tracking-gtm', { composed: true, bubbles: true, detail }));

      throw error;
    }

    this.dispatchEvent(new CustomEvent('next-step', { composed: true, bubbles: true }));
  }

  render() {
    return html`
      <tenable-form .errors=${this.form.errors} .values=${this.form.values} @form-submitted=${(e) => this.form.submitHandler(e)}>
        <tenable-form-error .errors=${this.form.formErrors}></tenable-form-error>

        <tenable-input type="hidden" name="product" value="${this.type}" @input-change=${(e) => this.form.inputHandler(e)}></tenable-input>

        <div class="d-flex justify-content-between">
          <tenable-form-group class="mr-3 flex-fill">
            <tenable-input type="text" name="first_name" label=${msg('First Name')} ?invalid=${this.form.errors.first_name} @input-change=${(e) => this.form.inputHandler(e)} @input-blur=${(e) => this.form.validateField(e)}></tenable-input>
            <tenable-form-feedback message=${this.form.errors.first_name}></tenable-form-feedback>
          </tenable-form-group>

          <tenable-form-group class="flex-fill">
            <tenable-input type="text" name="last_name" label=${msg('Last Name')} ?invalid=${this.form.errors.last_name} @input-change=${(e) => this.form.inputHandler(e)} @input-blur=${(e) => this.form.validateField(e)}></tenable-input>
            <tenable-form-feedback message=${this.form.errors.last_name}></tenable-form-feedback>
          </tenable-form-group>
        </div>

        <tenable-form-group>
          <tenable-input type="text" name="email" label=${msg('Business Email')} ?invalid=${this.form.errors.email} @input-change=${(e) => this.form.inputHandler(e)} @input-blur=${(e) => this.form.validateField(e)}></tenable-input>
          <tenable-form-feedback message=${this.form.errors.email}></tenable-form-feedback>
        </tenable-form-group>

        <tenable-form-group>
          <tenable-input type="text" name="phone" label=${msg('Phone')} ?invalid=${this.form.errors.phone} @input-change=${(e) => this.form.inputHandler(e)} @input-blur=${(e) => this.form.validateField(e)}></tenable-input>
          <tenable-form-feedback message=${this.form.errors.phone}></tenable-form-feedback>
        </tenable-form-group>

        <tenable-form-group>
          <tenable-input type="text" name="title" label=${msg('Title')} ?invalid=${this.form.errors.title} @input-change=${(e) => this.form.inputHandler(e)} @input-blur=${(e) => this.form.validateField(e)}></tenable-input>
          <tenable-form-feedback message=${this.form.errors.title}></tenable-form-feedback>
        </tenable-form-group>

        <tenable-form-group>
          <tenable-input type="text" name="organization" label=${this.type === 'education' ? msg('School/Training Program Name') : msg('Organization Name')} ?invalid=${this.form.errors.organization} @input-change=${(e) => this.form.inputHandler(e)} @input-blur=${(e) => this.form.validateField(e)}></tenable-input>
          <tenable-form-feedback message=${this.form.errors.organization}></tenable-form-feedback>
        </tenable-form-group>

        ${this.type === 'education'
    ? html`<tenable-form-group>
          <tenable-input type="text" name="course" label=${msg('Course Title')} ?invalid=${this.form.errors.course} @input-change=${(e) => this.form.inputHandler(e)} @input-blur=${(e) => this.form.validateField(e)}></tenable-input>
          <tenable-form-feedback message=${this.form.errors.course}></tenable-form-feedback>
        </tenable-form-group>` : ''}

        <tenable-form-group>
          <tenable-input type="text" name="address" label=${msg('Address')} ?invalid=${this.form.errors.address} @input-change=${(e) => this.form.inputHandler(e)} @input-blur=${(e) => this.form.validateField(e)}></tenable-input>
          <tenable-form-feedback message=${this.form.errors.address}></tenable-form-feedback>
        </tenable-form-group>

        <tenable-form-group>
          <tenable-input type="text" name="city" label=${msg('City')} ?invalid=${this.form.errors.city} @input-change=${(e) => this.form.inputHandler(e)} @input-blur=${(e) => this.form.validateField(e)}></tenable-input>
          <tenable-form-feedback message=${this.form.errors.city}></tenable-form-feedback>
        </tenable-form-group>

        <div class="d-flex justify-content-between">
          <tenable-form-group class="mr-3 flex-fill">
            <tenable-select
              name="country"
              label=${msg('Country')}
              ?invalid=${this.form.errors.country}
              @input-change=${(e) => this.form.inputHandler(e)}
              @input-blur=${(e) => this.form.validateField(e)}
              .options=${countries}
            >
            </tenable-select>
            <tenable-form-feedback message=${this.form.errors.country}></tenable-form-feedback>
          </tenable-form-group>

          <tenable-form-group class="flex-fill">
            <tenable-input type="text" name="region" label=${msg('Region')} ?invalid=${this.form.errors.region} @input-change=${(e) => this.form.inputHandler(e)} @input-blur=${(e) => this.form.validateField(e)}></tenable-input>
            <tenable-form-feedback message=${this.form.errors.region}></tenable-form-feedback>
          </tenable-form-group>
        </div>

        <tenable-form-group>
          <tenable-input type="text" name="zip" label=${msg('Postal Code')} ?invalid=${this.form.errors.zip} @input-change=${(e) => this.form.inputHandler(e)} @input-blur=${(e) => this.form.validateField(e)}></tenable-input>
          <tenable-form-feedback message=${this.form.errors.zip}></tenable-form-feedback>
        </tenable-form-group>

        <tenable-form-group>
          <tenable-input type="file" accept="application/pdf" multiple=${true} name="upload" label=${this.type === 'education' ? msg('Upload PDF of Course Curriculum or Documentation') : msg('Upload PDF of Nonprofit Status')} ?invalid=${this.form.errors.upload} @input-change=${(e) => this.form.inputHandler(e)} @input-blur=${(e) => this.form.validateField(e)}></tenable-input>
          <tenable-form-feedback message=${this.form.errors.upload}></tenable-form-feedback>
        </tenable-form-group>

        <tenable-form-group>
          <p><small>${msg(html`By requesting this license, Tenable may send you email communications regarding its products and services. You may opt out of receiving these communications at any time by using the unsubscribe link located in the footer of the emails delivered to you. You can also manage your Tenable email preferences by visiting the <a target="_blank" href="https://info.tenable.com/SubscriptionManagement.html">Subscription Management Page</a>.`)}</small></p>
          <p><small>${msg(html`Tenable will only process your personal data in accordance with its <a target="_blank" href="https://www.tenable.com/privacy-policy">Privacy Policy</a>.`)}</small></p>
          <label for="consentOptIn">
            <tenable-input
              type="checkbox"
              name="consentOptIn"
              .value=${false}
              ?invalid=${this.form.errors.consentOptIn}
              @input-change=${(e) => this.form.inputHandler(e)}
              @input-blur=${(e) => this.form.validateField(e)}>
            </tenable-input>
            ${msg(html`I agree to Tenable's <a href="https://static.tenable.com/prod_docs/tenable_slas.html">software license agreements</a>`)}
          </label>
          <tenable-form-feedback message=${this.form.errors.consentOptIn}></tenable-form-feedback>
        </tenable-form-group>

        <tenable-form-group>
          <tenable-button type="submit" color="primary" ?disabled=${this.form.isSubmitting}>
            ${this.form.isSubmitting ? msg('Sending...') : msg('Submit Request')}
          </tenable-button>
        </tenable-form-group>
      </tenable-form>
    `;
  }
}

customElements.define('tenable-nonprofit-form', NonprofitForm);
