import { LitElement, html } from 'lit';
import { msg, updateWhenLocaleChanges } from '@lit/localize';
import styles from './styles';

class OsSignaturesConfirmation extends LitElement {
  static styles = styles;

  constructor() {
    super();
    updateWhenLocaleChanges(this);
  }

  render() {
    return html`
      <div>${msg('Thank you for your feedback!')}</div>
    `;
  }
}

customElements.define('tenable-os-signatures-confirmation', OsSignaturesConfirmation);
