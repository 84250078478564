const getDefaultEnv = (env, hostnames = {}) => {
  const { hostname } = window?.location;
  const defaultHostnames = {
    local: ['localhost'],
    dev: ['dev.tenable.com'],
    staging: ['staging.tenable.com'],
    production: ['www.tenable.com'],
    ...hostnames,
  };

  if (env) {
    return { env };
  }

  if (defaultHostnames?.local?.includes(hostname) ?? false) {
    return { env: 'local' };
  }

  if (defaultHostnames?.dev?.includes(hostname) ?? false) {
    return { env: 'dev' };
  }

  if (defaultHostnames?.staging?.includes(hostname) ?? false) {
    return { env: 'staging' };
  }

  if (defaultHostnames?.production?.includes(hostname) ?? false) {
    return { env: 'production' };
  }

  return { env: 'production' };
};

const getEnvVars = (env = 'production') => {
  let baseUrl = 'https://www.tenable.com';
  if (env === 'local') baseUrl = 'http://localhost:3000';
  if (env === 'dev') baseUrl = 'https://dev.tenable.com';
  if (env === 'staging') baseUrl = 'https://staging.tenable.com';

  return { baseUrl, environment: env };
};

const escapeHTML = (html) => {
  const escape = document.createElement('textarea');
  escape.textContent = html;
  return escape.innerHTML;
};

const getProductName = (id) => {
  const products = {
    vm: 'Tenable Vulnerability Management',
    consec: 'Tenable Vulnerability Management', // VM cuz no more CONSEC
    was: 'Tenable Web App Scanning',
    nessus: 'Tenable Nessus Professional',
    tio: 'Tenable Vulnerability Management',
    lumin: 'Tenable Vulnerability Management', // No more Lumin standalone
    cns: 'Tenable Cloud Security',
    essentials: 'Tenable Nessus Essentials',
    expert: 'Tenable Nessus Expert',
    'nessus-extension': 'Nessus Extension',
    'nessus-extension-extended': 'Nessus Extension',
  };

  return products[id];
};

const getAppsArray = (product) => {
  if (product === 'nessus') {
    return ['nessus'];
  }

  if (product === 'expert') {
    return ['expert'];
  }

  if (product === 'essentials') {
    return ['essentials'];
  }

  if (['nessus-extension', 'nessus-extension-extended', 'nessus-pro-to-expert'].includes(product)) {
    return [product];
  }

  return ['vm', 'was', 'lumin'];
};

const createTargetElement = (options = {}) => {
  const targets = [];
  const { elementType } = options;
  const targetDivSelectors = options?.targetDivSelectors ?? [];

  targetDivSelectors.forEach((id) => {
    const target = document.getElementById(id);
    if (target) targets.push(target);
  });

  if (targets.length < 1) return null;

  if (!elementType) {
    console.error('Element Type is not defined');
    return null;
  }

  const element = document.createElement(options?.elementType);
  targets[0].appendChild(element);

  return element;
};

const updateUrlParams = (params) => {
  const { origin, pathname } = window.location;
  const query = params.toString().length > 0 ? `?${params.toString()}` : '';
  const url = `${origin}${pathname}${query}`;
  window.history.replaceState(null, null, url);
};

export {
  getProductName,
  getEnvVars,
  getDefaultEnv,
  getAppsArray,
  createTargetElement,
  escapeHTML,
  updateUrlParams,
};
