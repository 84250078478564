import { html, LitElement } from 'lit';
import { ContextProvider } from '@lit-labs/context';
import stepWizardConext from './StepWizardConext';

class StepWizardContextProvider extends LitElement {
  static properties = {
    value: { type: Object },
  };

  constructor() {
    super();
    this.value = {};
  }

  provider = new ContextProvider(this, {
    context: stepWizardConext,
    initialValue: this.value,
  });

  connectedCallback() {
    super.connectedCallback();
    this.provider.setValue(this.value);
  }

  setContextValue(event) {
    const value = { ...this.value, ...event.detail };
    this.provider.setValue(value);
    this.value = value;
  }

  render() {
    this.provider.setValue(this.value);

    return html`
    <div
      @set-step-wizard-context=${(e) => this.setContextValue(e)}
    >
      <slot></slot>
    </div>
  `;
  }
}
customElements.define('tenable-step-wizard-provider', StepWizardContextProvider);
