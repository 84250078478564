import { ContextConsumer } from '@lit-labs/context';
import appConext from './AppConext';

const AppContextConsumer = (host, options = {}) => new ContextConsumer(host, {
  context: appConext,
  subscribe: true,
  ...options,
});

export default AppContextConsumer;
