import { LitElement, html } from 'lit';
import { msg, updateWhenLocaleChanges } from '@lit/localize';
import styles from './styles';
import '../../../shared/components/form/Form';
import '../../../shared/components/form-group/FormGroup';
import '../../../shared/components/form-feedback/FormFeedback';
import '../../../shared/components/form-error/FormError';
import '../../../shared/components/input/Input';
import '../../../shared/components/button/Button';
import FormController from '../../../shared/controllers/FormController';
import schema from '../../../../../schemas/UnauthorizedScanReportSchema';
import messages from '../../../../../schemas/UnauthorizedScanReportSchema/messages';
import UnauthorizedScanReportApi from '../../../shared/api/UnauthorizedScanReportApi';
import AppContextConsumer from '../../../shared/context/app-context/AppContextConsumer';

class UnauthorizedScanReportForm extends LitElement {
  static styles = styles;

  constructor() {
    super();
    updateWhenLocaleChanges(this);

    this.messages = messages();
  }

  context = AppContextConsumer(this);

  form = new FormController(this, { onSubmit: (params) => this.onSubmit(params) });

  connectedCallback() {
    super.connectedCallback();

    this.UnauthorizedScanReportApi = new UnauthorizedScanReportApi({ env: this.context.value?.env });
    this.form.schema = schema(this.messages);
  }

  async onSubmit({ values }) {
    const payload = { ...values, ...this.context?.value?.tracking ?? {} };
    payload.startDate = `${payload.startDate} ${Intl.DateTimeFormat().resolvedOptions().timeZone}`;
    payload.stopDate = `${payload.stopDate} ${Intl.DateTimeFormat().resolvedOptions().timeZone}`;

    try {
      await this.UnauthorizedScanReportApi.create(payload);
      this.dispatchEvent(new CustomEvent('tracking-conversion', { composed: true, bubbles: true, detail: payload }));
    } catch (error) {
      const detail = { category: this.context?.value?.tracking?.gtm?.category, action: 'Server Fail', label: error };
      this.dispatchEvent(new CustomEvent('tracking-gtm', { composed: true, bubbles: true, detail }));

      throw error;
    }

    this.dispatchEvent(new CustomEvent('next-step', { composed: true, bubbles: true }));
  }

  render() {
    return html`
      <tenable-form .errors=${this.form.errors} .values=${this.form.values} @form-submitted=${(e) => this.form.submitHandler(e)}>
        <tenable-form-error .errors=${this.form.formErrors}></tenable-form-error>

        <tenable-form-group>
          <tenable-input type="text" name="ipTargets" label=${msg('Which IP(s) were targeted in the unauthorized scan?')} ?invalid=${this.form.errors.ipTargets} @input-change=${(e) => this.form.inputHandler(e)} @input-blur=${(e) => this.form.validateField(e)}></tenable-input>
          <tenable-form-feedback message=${this.form.errors.ipTargets}></tenable-form-feedback>
          <p><small>${msg('Please use CIDR notation (ie: 0.0.0.0/24 or 1.1.1.1/32)')}</small></p>
        </tenable-form-group>

        <tenable-form-group>
          <tenable-input type="text" name="ipSource" label=${msg('What were the source IP(s) from which the unauthorized scan originated?')} ?invalid=${this.form.errors.ipSource} @input-change=${(e) => this.form.inputHandler(e)} @input-blur=${(e) => this.form.validateField(e)}></tenable-input>
          <tenable-form-feedback message=${this.form.errors.ipSource}></tenable-form-feedback>
          <p><small>${msg('Please use CIDR notation (ie: 0.0.0.0/24 or 1.1.1.1/32)')}</small></p>
        </tenable-form-group>

        <tenable-form-group>
          <tenable-input type="datetime-local" name="startDate" label=${msg('Date/time (in your local timezone) the unauthorized scan traffic started?')} ?invalid=${this.form.errors.startDate} @input-change=${(e) => this.form.inputHandler(e)} @input-blur=${(e) => this.form.validateField(e)}></tenable-input>
          <tenable-form-feedback message=${this.form.errors.startDate}></tenable-form-feedback>
        </tenable-form-group>

        <tenable-form-group>
          <tenable-input type="datetime-local" name="stopDate" label=${msg('Date/time (in your local timezone) the unauthorized scan traffic stopped?')} ?invalid=${this.form.errors.stopDate} @input-change=${(e) => this.form.inputHandler(e)} @input-blur=${(e) => this.form.validateField(e)}></tenable-input>
          <tenable-form-feedback message=${this.form.errors.stopDate}></tenable-form-feedback>
        </tenable-form-group>

        <tenable-form-group>
          <p><small>${msg('A customer may have proceeded with an understanding that they had authorization from someone in your organization to do the scan. Please confirm you have checked with related business stakeholders to determine if this scan was authorized.')}</small></p>
          <tenable-input type="checkbox" name="stakeholderConfirmation" label-type="after" label=${msg('I have checked with related business stakeholders and this scan was not authorized.')} ?invalid=${this.form.errors.stakeholderConfirmation} @input-change=${(e) => this.form.inputHandler(e)} @input-blur=${(e) => this.form.validateField(e)}></tenable-input>
          <tenable-form-feedback message=${this.form.errors.stakeholderConfirmation}></tenable-form-feedback>
        </tenable-form-group>

        <tenable-form-group>
          <tenable-input type="email" placeholder="${msg('Email')}" name="email" label=${msg('Once you submit this form we will investigate which of our customers initiated this scanning activity. Please provide an email address below for us to follow up with after the investigation is complete.')} ?invalid=${this.form.errors.email} @input-change=${(e) => this.form.inputHandler(e)} @input-blur=${(e) => this.form.validateField(e)}></tenable-input>
          <tenable-form-feedback message=${this.form.errors.email}></tenable-form-feedback>
        </tenable-form-group>

        <tenable-form-group>
          <tenable-button type="submit" color="primary" ?disabled=${this.form.isSubmitting}>
            ${this.form.isSubmitting ? msg('Submitting...') : msg('Submit Request')}
          </tenable-button>
        </tenable-form-group>
      </tenable-form>
    `;
  }
}

customElements.define('tenable-unauthorized-scan-report-form', UnauthorizedScanReportForm);
