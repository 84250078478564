import { css } from 'lit';

export default [
  css`
  .form-group {
    margin: 0 0 1em;
  }

  .form-group ::slotted(label) {
    margin: 0 0 .25rem;
    display: block;
    font-weight: var(--tenable-label-font-weight, 600);
    color: var(--tenable-label-color, #425363);
  }
  `,
];
