import { css } from 'lit';
import sharedStyles from '../../../shared/styles';

export default [
  sharedStyles,
  css`
  :host {
    text-align: center;
  }
  `,
];
