import { html, LitElement } from 'lit';
import { updateWhenLocaleChanges } from '@lit/localize';

class IconValidation extends LitElement {
  static properties = {
    color: {
      type: String,
    },
    width: {
      type: String,
    },
  };

  constructor() {
    super();
    this.color = '#f87b1e';
    this.width = '6em';
    updateWhenLocaleChanges(this);
  }

  render() {
    return html`
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width="${this.width}" stroke="${this.color}" stroke-width="1.5" fill="none">
        <path d="M8 14.5C11.5899 14.5 14.5 11.5899 14.5 8C14.5 4.41015 11.5899 1.5 8 1.5C4.41015 1.5 1.5 4.41015 1.5 8C1.5 11.5899 4.41015 14.5 8 14.5Z" strokeWidth="1.25" strokeMiterlimit="10" strokeLinecap="round" />
        <path d="M8.01 11L8 11.01" strokeWidth="1.25" stroke-miterlimit="10" stroke-linecap="round" strokeLinejoin="round" />
        <path d="M8 8.55V4.75" stroke-miterlimit="10" stroke-linejoin="round" />
      </svg>
    `;
  }
}
customElements.define('tenable-icon-validation', IconValidation);
