const { object, string } = require('yup');

const schema = (messages = {}) => object().shape({
  first_name: string()
    .required(messages?.first_name?.required ?? 'First Name is required'),
  last_name: string()
    .required(messages?.last_name?.required ?? 'Last Name is required'),
  email: string()
    .email(messages?.email?.email ?? 'Invalid email address')
    .required(messages?.email?.required ?? 'Email address cannot be blank'),
  phone: string()
    .required(messages?.phone?.required ?? 'Phone is required'),
  title: string()
    .required(messages?.title?.required ?? 'Title is required'),
  company: string()
    .required(messages?.company?.required ?? 'Company is required'),
  companySize: string()
    .required(messages?.companySize?.required ?? 'Company Size is required'),
  partnerId: string(),
});

module.exports = schema;
