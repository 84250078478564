import { html, LitElement } from 'lit';
import { msg, updateWhenLocaleChanges } from '@lit/localize';
import styles from './styles';

class ConsentDisclosure extends LitElement {
  static styles = styles;

  constructor() {
    super();
    updateWhenLocaleChanges(this);
  }

  render() {
    return html`
      <p><small>${msg(html`By registering for this trial license, Tenable may send you email communications regarding its products and services. You may opt out of receiving these communications at any time by using the unsubscribe link located in the footer of the emails delivered to you. You can also manage your Tenable email preferences by visiting the <a target="_blank" href="https://info.tenable.com/SubscriptionManagement.html">Subscription Management Page</a>.`)}</small></p>
      <p><small>${msg(html`Tenable will only process your personal data in accordance with its <a target="_blank" href="https://www.tenable.com/privacy-policy">Privacy Policy</a>.`)}</small></p>
    `;
  }
}
customElements.define('tenable-consent-disclosure', ConsentDisclosure);
