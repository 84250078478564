import { msg } from '@lit/localize';

class FormController {
  constructor(host, { schema, onSubmit }) {
    this.host = host;
    this.onSubmit = onSubmit;
    this.#schema = schema || {};
    host.addController(this);
  }

  #schema = {};

  #errors = {};

  #values = {};

  #isSubmitting = false;

  #formErrors = [];

  get schema() {
    return this.#schema;
  }

  get errors() {
    return this.#errors;
  }

  get values() {
    return this.#values;
  }

  get isSubmitting() {
    return this.#isSubmitting;
  }

  get formErrors() {
    return this.#formErrors;
  }

  set errors(errors) {
    this.#errors = errors;
    this.host.requestUpdate();
  }

  set schema(schema) {
    this.#schema = schema;
    this.host.requestUpdate();
  }

  set values(values) {
    this.#values = values;
    this.host.requestUpdate();
  }

  set isSubmitting(isSubmitting) {
    this.#isSubmitting = isSubmitting;
    this.host.requestUpdate();
  }

  set formErrors(formErrors) {
    this.#formErrors = formErrors;
    this.host.requestUpdate();
  }

  inputHandler(event) {
    const value = event?.detail?.value;

    const values = {};
    values[event.detail.name] = value;

    this.values = {
      ...this.values,
      ...values,
    };
  }

  async validateField(event) {
    const { errors } = this;

    if (!this?.schema?.validateAt === 'function') return;

    try {
      await this.schema.validateAt(event.detail.name, this.values);
      delete errors[event.detail.name];
    } catch (err) {
      errors[event.detail.name] = err.message;
    }

    this.errors = {
      ...this.errors,
      ...errors,
    };
  }

  async validate() {
    if (!this?.schema?.validate === 'function') return true;

    try {
      await this.schema.validate(this.values, { abortEarly: false });
    } catch (err) {
      console.error('Validation Error', { err });

      err.inner.forEach((error) => {
        this.errors = {
          ...this.errors,
          [error.path]: error.message,
        };
      });

      return false;
    }

    return true;
  }

  async submitHandler(event) {
    event.preventDefault();
    this.isSubmitting = true;
    this.formErrors = [];

    const isValid = await this.validate();
    if (!isValid) {
      this.isSubmitting = false;
      return;
    }

    try {
      await this.onSubmit({ values: this.values, errors: this.errors });
    } catch (err) {
      console.error('Form Submission Error', { err });
      this.formErrors = [err?.message ?? msg('Submission failed')];
    }

    this.isSubmitting = false;
  }
}

export default FormController;
