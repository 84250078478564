const { object, string } = require('yup');

const schema = (messages = {}) => object().shape({
  first_name: string(messages?.first_name?.string ?? 'Invalid first name')
    .required(messages?.first_name?.required ?? 'First Name is required'),
  last_name: string(messages?.last_name?.string ?? 'Invalid last name')
    .required(messages?.last_name?.required ?? 'Last Name is required'),
  email: string()
    .email(messages?.email?.email ?? 'Invalid email address')
    .required(messages?.email?.required ?? 'Email address cannot be blank'),
  partnerId: string(),
});

module.exports = schema;
