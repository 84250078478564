import { css } from 'lit';

export default [
  css`
  :host {
    position: relative;
    border-radius: .5rem;
    color: var(--tenable-code-color, #415465);
    background-color: var(--tenable-code-background, #e5e9ed);
    line-height: 20px;
    font-size: 1rem;
  }

  pre {
    margin: 0;
    padding: .75rem;
    border-radius: .5rem;
    max-width: 70rem;
    background-color: var(--tenable-code-background, #e5e9ed);
    overflow: auto;
    max-width: 70em;
  }

  code {
    font-size: 80%;
    font-weight: 400;
    background-color: var(--tenable-code-background, #e5e9ed);
  }

  .code-block {
    position: relative;
    padding: .75rem 2.25rem .75rem .75rem;
    border-radius: .5rem;
    color: var(--tenable-code-color, #415465);
    line-height: 20px;
    background-color: var(--tenable-code-background, #e5e9ed);
  }

  .copy-button {
    position: absolute;
    top: 1em;
    right: 1em;
  }
  `,
];
