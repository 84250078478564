const { object, string, boolean, addMethod } = require('yup');

function ipv4(message = 'Invalid IP address') {
  return this.matches(/(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)(?:\.(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)){3}/, {
    message,
    excludeEmptyString: true,
  }).test('ip', message, (value) => (
    value === undefined || value.trim() === ''
      ? true
      : value.split('.').find((i) => parseInt(i, 10) > 255) === undefined
  ));
}

addMethod(string, 'ipv4', ipv4);

const schema = (messages = {}) => object().shape({
  ipTargets: string()
    .ipv4()
    .required(messages?.ipTargets?.required ?? 'Targeted IPs cannot be blank'),
  ipSource: string()
    .ipv4()
    .required(messages?.sourceTargets?.required ?? 'Source IPs cannot be blank'),
  email: string()
    .email(messages?.email?.email ?? 'Invalid email address')
    .required(messages?.email?.required ?? 'Email address cannot be blank'),
  startDate: string()
    .required(messages?.startDate?.required ?? 'Start Date cannot be blank'),
  stopDate: string()
    .required(messages?.stopDate?.required ?? 'Stop Date cannot be blank'),
  stakeholderConfirmation: boolean()
    .required(messages?.stakeholderConfirmation?.required ?? 'You must check with related stakeholders to proceed.')
    .oneOf([true], messages?.stakeholderConfirmation?.required ?? 'You must check with related stakeholders to proceed.'),
});

module.exports = schema;
