import { msg } from '@lit/localize';

const messages = () => ({
  code: {
    matches: msg('Invalid Activation Code format'),
    required: msg('Activation Code cannot be blank'),
  },
  email: {
    email: msg('Invalid email address'),
    required: msg('Email address cannot be blank'),
  },
});

export default messages;
