import {
  LitElement,
  html,
} from 'lit';
import { classMap } from 'lit/directives/class-map.js';
import { ifDefined } from 'lit/directives/if-defined.js';
import styles from './styles';
import '../tooltip/Tooltip';
import '../icons/IconValidation';

class Textarea extends LitElement {
  static styles = styles;

  static properties = {
    name: { type: String },
    id: { type: String },
    placeholder: { type: String },
    rows: { type: String },
    cols: { type: String },
    minlength: { type: String },
    maxLength: { type: String },
    invalid: { type: Boolean, reflect: true },
    required: { type: Boolean, reflect: true },
    autofocus: { type: Boolean, reflect: true },
    autocomplete: { type: String, reflect: true },
    disabled: { type: Boolean, reflect: true },
    readonly: { type: Boolean, reflect: true },
    ariaDescribedBy: { type: String },
    ariaLabel: { type: String },
    ariaInvalid: { type: String },
    value: { type: String, reflect: true },
    label: { type: String },
    labelType: {
      type: String,
      attribute: 'label-type',
      converter: (value) => (['before', 'after', 'around'].includes(value) ? value : 'before'),
    },
    tooltip: { type: String },
    tooltipColor: { type: String },
    tooltipWidth: { type: String },
  };

  constructor() {
    super();
    this.name = '';
    this.id = undefined;
    this.placeholder = '';
    this.rows = undefined;
    this.cols = undefined;
    this.minlength = undefined;
    this.maxLength = undefined;
    this.invalid = false;
    this.required = false;
    this.autofocus = false;
    this.autocomplete = undefined;
    this.disabled = false;
    this.readonly = false;
    this.ariaDescribedBy = '';
    this.ariaLabel = '';
    this.ariaInvalid = '';
    this.value = '';
    this.label = '';
    this.labelType = 'before';
    this.tooltip = undefined;
    this.tooltipColor = '#425363';
    this.tooltipWidth = '1.25em';
  }

  #handleChange(event) {
    const { value } = event.target;

    this.value = value;
    this.dispatchEvent(
      new CustomEvent('input-change', {
        bubbles: true,
        composed: true,
        detail: {
          srcEvent: event,
          name: this.name,
          value,
        },
      }),
    );
  }

  #handleBlur(event) {
    this.dispatchEvent(
      new CustomEvent('input-blur', {
        bubbles: true,
        composed: true,
        detail: {
          srcEvent: event,
          name: this.name,
        },
      }),
    );
  }

  #handleKeyDown(event) {
    this.dispatchEvent(
      new CustomEvent('input-keydown', {
        bubbles: true,
        composed: true,
        detail: {
          srcEvent: event,
        },
      }),
    );
  }

  #handleFocus(event) {
    if (!this.disabled) {
      this.dispatchEvent(
        new CustomEvent('input-focus', {
          bubbles: true,
          composed: true,
          detail: {
            srcEvent: event,
          },
        }),
      );
    }
  }

  #handleMouseDown(event) {
    if (!this.disabled) {
      this.dispatchEvent(
        new CustomEvent('input-mousedown', {
          bubbles: true,
          composed: true,
          detail: {
            srcEvent: event,
          },
        }),
      );
    }
  }

  render() {
    const textarea = html`<textarea
      @blur=${this.#handleBlur}
      @input=${this.#handleChange}
      @focus=${this.#handleFocus}
      @keydown=${this.#handleKeyDown}
      @mousedown=${this.#handleMouseDown}
      class=${classMap({ invalid: this.invalid })}
      rows=${this.rows}
      cols=${this.cols}
      name=${ifDefined(this.name || undefined)}
      id=${ifDefined(this.id)}
      data-testid=${ifDefined(this.id ?? this.name)}
      ?invalid=${this.invalid}
      ?required=${this.required}
      ?autofocus=${this.autofocus}
      ?autocomplete=${this.autocomplete}
      ?disabled=${this.disabled}
      ?readonly=${this.readOnly}
      aria-describedby=${this.ariaDescribedBy}
      aria-label=${this.ariaLabel}
      placeholder=${this.placeholder}
      minlength=${ifDefined(this.minlength)}
      maxlength=${ifDefined(this.maxLength)}
      .value=${this.value}
    ></textarea>`;
    if (!this.label) return html`${textarea}`;

    if (this.labelType === 'before') {
      return this.tooltip
        ? html`
          <label for=${this.name}>${this.label}
            <tenable-tooltip message="${this.tooltip}" direction="above">
              <tenable-icon-validation color="${this.tooltipColor}" width="${this.tooltipWidth}"></tenable-icon-validation>
            </tenable-tooltip>
          </label>
          ${textarea}`
        : html`<label for=${this.name}>${this.label}</label>${textarea}`;
    }

    if (this.labelType === 'around') {
      return this.tooltip
        ? html`
          <label for=${this.name}>
            ${this.label}
            <tenable-tooltip message="${this.tooltip}" direction="above">
              <tenable-icon-validation color="${this.tooltipColor}" width="${this.tooltipWidth}"></tenable-icon-validation>
            </tenable-tooltip>
            ${textarea}
          </label>`
        : html`<label for=${this.name}>${this.label}${textarea}</label>`;
    }

    if (this.labelType === 'after') {
      return this.tooltip
        ? html`
          <label for=${this.name}>
            ${textarea}
            ${this.label}
            <tenable-tooltip message="${this.tooltip}" direction="above">
              <tenable-icon-validation color="${this.tooltipColor}" width="${this.tooltipWidth}"></tenable-icon-validation>
            </tenable-tooltip>
          </label>`
        : html`<label for=${this.name}>${textarea}${this.label}</label>`;
    }

    return '';
  }
}
customElements.define('tenable-textarea', Textarea);
