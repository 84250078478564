import { LitElement, html } from 'lit';
import { msg, updateWhenLocaleChanges } from '@lit/localize';
import styles from './styles';
import '../../../../shared/components/form/Form';
import '../../../../shared/components/form-group/FormGroup';
import '../../../../shared/components/form-feedback/FormFeedback';
import '../../../../shared/components/form-error/FormError';
import '../../../../shared/components/input/Input';
import '../../../../shared/components/button/Button';
import '../../../../shared/components/code/Code';
import '../../../../shared/components/step-wizard-controls/StepWizardControls';
import '../../../../shared/components/select/Select';
import '../../../shared/consent-disclosure/ConsentDisclosure';
import FormController from '../../../../shared/controllers/FormController';
import schema from '../../../../../../schemas/NewContactSchema';
import messages from '../../../../../../schemas/NewContactSchema/messages';
import TrialApi from '../../../../shared/api/TrialApi';
import AppContextConsumer from '../../../../shared/context/app-context/AppContextConsumer';

class NessusNewContact extends LitElement {
  static styles = styles;

  static properties = {
    contact: {
      type: Object,
    },
    product: {
      type: String,
    },
    partnerId: {
      type: String,
      attribute: 'partner-id',
      reflecct: true,
    },
    hideHeadings: {
      type: Boolean,
      attribute: 'hide-headings',
      reflecct: true,
    },
    hideLabels: {
      type: Boolean,
      attribute: 'hide-labels',
      reflecct: true,
    },
    floatingLabels: {
      type: Boolean,
      attribute: 'floating-labels',
      reflecct: true,
    },
    options: {
      type: Array,
      state: true,
    },
    flexButtons: {
      type: Boolean,
      attribute: 'flex-buttons',
      reflecct: true,
    },
  };

  constructor() {
    super();
    updateWhenLocaleChanges(this);

    this.messages = messages();
    this.contact = {};
    this.product = 'nessus';
    this.partnerId = '';
    this.hideHeadings = false;
    this.hideLabels = false;
    this.floatingLabels = false;
    this.flexButtons = false;
    this.options = [
      { value: '', label: '', placeholder: true },
      { value: '1-9', label: '1-9' },
      { value: '10-49', label: '10-49' },
      { value: '50-99', label: '50-99' },
      { value: '100-249', label: '100-249' },
      { value: '250-499', label: '250-499' },
      { value: '500-999', label: '500-999' },
      { value: '1,000-3,499', label: '1,000-3,499' },
      { value: '3,500-4,999', label: '3,500-4,999' },
      { value: '5,000-10,000', label: '5,000-10,000' },
      { value: '10,000+', label: '10,000+' },
    ];
  }

  context = AppContextConsumer(this);

  form = new FormController(this, { onSubmit: (params) => this.onSubmit(params) });

  connectedCallback() {
    super.connectedCallback();

    this.TrialApi = new TrialApi({ env: this.context.value?.env });
    this.form.schema = schema(this.messages);
    this.partnerId = this.context?.value?.partnerId ?? '';
    this.hideHeadings = this.context?.value?.hideHeadings ?? false;
    this.hideLabels = this.context?.value?.hideLabels ?? false;
    this.floatingLabels = this.context?.value?.floatingLabels ?? false;
    this.flexButtons = this.context?.value?.flexButtons ?? false;
  }

  async onSubmit({ values }) {
    const payload = { ...values, ...this.context?.value?.tracking ?? {} };

    try {
      await this.TrialApi.create(payload);
      this.dispatchEvent(new CustomEvent('set-active-step', { composed: true, bubbles: true, detail: { step: 2 } }));
      this.dispatchEvent(new CustomEvent('tracking-conversion', { composed: true, bubbles: true, detail: { product: this.product, appsArr: this.context?.value?.tracking?.apps } }));
    } catch (error) {
      const detail = { category: this.context?.value?.tracking?.gtm?.category, action: 'Server Fail', label: error };
      this.dispatchEvent(new CustomEvent('tracking-gtm', { composed: true, bubbles: true, detail }));

      throw error;
    }
  }

  render() {
    return html`
      ${!this.hideHeadings ? html`<h4>${msg('Please provide the following information to create an account and start your trial.')}</h4>` : ''}
      <tenable-form .errors=${this.form.errors} .values=${this.form.values} @form-submitted=${(e) => this.form.submitHandler(e)}>
        <tenable-form-error .errors=${this.form.formErrors}></tenable-form-error>

        <tenable-input type="hidden" name="skipContactLookup" value=${true} @input-change=${(e) => this.form.inputHandler(e)}></tenable-input>
        <tenable-input type="hidden" name="product" value="${this.product}" @input-change=${(e) => this.form.inputHandler(e)}></tenable-input>
        <tenable-input type="hidden" name="first_name" value="${this.contact.first_name}" @input-change=${(e) => this.form.inputHandler(e)}></tenable-input>
        <tenable-input type="hidden" name="last_name" value="${this.contact.last_name}" @input-change=${(e) => this.form.inputHandler(e)}></tenable-input>
        <tenable-input type="hidden" name="email" value="${this.contact.email}" @input-change=${(e) => this.form.inputHandler(e)}></tenable-input>
        <tenable-input type="hidden" name="partnerId" value="${this.partnerId}" @input-change=${(e) => this.form.inputHandler(e)}></tenable-input>

        <tenable-form-group>
          <tenable-input type="text" name="phone" label="${msg('Phone')}" ?hideLabel=${this.hideLabels} label-type=${this.floatingLabels ? 'float' : 'before'} ?invalid=${this.form.errors.phone} @input-change=${(e) => this.form.inputHandler(e)} @input-blur=${(e) => this.form.validateField(e)}></tenable-input>
          <tenable-form-feedback message=${this.form.errors.phone}></tenable-form-feedback>
        </tenable-form-group>

        <tenable-form-group>
          <tenable-input type="text" name="title" label="${msg('Title')}" ?hideLabel=${this.hideLabels} label-type=${this.floatingLabels ? 'float' : 'before'} ?invalid=${this.form.errors.title} @input-change=${(e) => this.form.inputHandler(e)} @input-blur=${(e) => this.form.validateField(e)}></tenable-input>
          <tenable-form-feedback message=${this.form.errors.title}></tenable-form-feedback>
        </tenable-form-group>

        <tenable-form-group>
          <tenable-input type="text" name="company" label="${msg('Company')}" ?hideLabel=${this.hideLabels} label-type=${this.floatingLabels ? 'float' : 'before'} ?invalid=${this.form.errors.company} @input-change=${(e) => this.form.inputHandler(e)} @input-blur=${(e) => this.form.validateField(e)}></tenable-input>
          <tenable-form-feedback message=${this.form.errors.company}></tenable-form-feedback>
        </tenable-form-group>

        <tenable-form-group>
          <tenable-select
            name="companySize"
            label="${msg('Company Size (Employees)')}"
            ?hideLabel=${this.hideLabels}
            label-type=${this.floatingLabels ? 'float' : 'before'}
            ?invalid=${this.form.errors.companySize}
            @input-change=${(e) => this.form.inputHandler(e)}
            @input-blur=${(e) => this.form.validateField(e)}
            .options=${this.options}
          >
          </tenable-select>
          <tenable-form-feedback message=${this.form.errors.companySize}></tenable-form-feedback>
        </tenable-form-group>

        <tenable-form-group>
          <tenable-step-wizard-controls
            @next-click=${(e) => this.form.submitHandler(e)}
            next-label="${this.form.isSubmitting ? msg('Submitting...') : msg('Start Trial')}"
            previous-label="${msg('Go Back')}"
            previous-btn-color="link"
            .previousBtnStyle=${{ width: '100%', padding: '.5em 1.5em' }}
            .style=${this.flexButtons ? { display: 'flex', 'justify-content': 'center' } : { display: 'block' }}
            ?next-disabled=${this.form.isSubmitting}
            ?previous-disabled=${this.form.isSubmitting}
            previous-step="0"
          >
          </tenable-step-wizard-controls>
        </tenable-form-group>

        <tenable-consent-disclosure></tenable-consent-disclosure>
      </tenable-form>
    `;
  }
}

customElements.define('tenable-nessus-new-contact', NessusNewContact);
