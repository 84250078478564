import { css } from 'lit';

export default [
  css`
    button {
      display: var(--tenable-button-display, inline-block);
      font-weight: var(--tenable-button-font-weight, 400);
      text-align: var(--tenable-button-text-align, center);
      white-space: var(--tenable-button-white-space, nowrap);
      vertical-align: var(--tenable-button-vertical-align, middle);
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      border: var(--tenable-button-border, 1px solid transparent);
      padding: var(--tenable-button-padding, .5rem 1.5rem);
      font-family: var(--tenable-button-font-family, 1rem, barlow,system-ui,-apple-system,roboto,segoe ui,sans-serif);
      font-size: var(--tenable-button-font-size, 1rem);
      line-height: var(--tenable-button-line-height, 1.5);
      border-radius: var(--tenable-button-border-radius, .25rem);
      transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
      width: var(--tenable-button-width, 100%);
    }

    button:not(:disabled):not(.disabled) {
      cursor: pointer;
    }

    button.disabled,
    button:disabled {
      opacity: var(--tenable-btn-primary-disabled-opacity, .65);
      pointer-events: none;
    }

    .btn-lg {
      font-size: var(--tenable-button-font-size, 1.2rem);
      padding: var(--tenable-button-padding, .3rem 1rem);
    }

    .btn-sm {
      font-size: var(--tenable-button-font-size, .875rem);
      padding: var(--tenable-button-padding, .25rem .5rem);
    }

    .btn-primary {
      color: var(--tenable-btn-primary-color, #fff);
      background-color: var(--tenable-btn-primary-background-color, #007bff);
      border-color: var(--tenable-btn-primary-border-color, #007bff);
    }

    .btn-primary:hover {
      color: var(--tenable-btn-primary-hover-color, #fff);
      background-color: var(--tenable-btn-primary-hover-background-color, #0069d9);
      border-color: var(--tenable-btn-primary-hover-border-color, #0062cc);
    }

    .btn-primary.disabled,
    .btn-primary:disabled {
      color: var(--tenable-btn-primary-disabled-color, #fff);
      background-color: var(--tenable-btn-primary-disabled-background-color, #007bff);
      border-color: var(--tenable-btn-primary-disabled-border-color, #007bff);
    }

    .btn-secondary {
      color: var(--tenable-btn-secondary-color, #fff);
      background-color: var(--tenable-btn-secondary-background-color, #415465);
      border-color: var(--tenable-btn-secondary-border-color, #415465);
    }

    .btn-secondary:hover {
      color: var(--tenable-btn-primary-hover-color, #fff);
      background-color: var(--tenable-btn-primary-hover-background-color, #32414e);
      border-color: var(--tenable-btn-primary-hover-border-color, #32414e);
    }

    .btn-secondary.disabled,
    .btn-secondary:disabled {
      color: var(--tenable-btn-secondary-disabled-color, #fff);
      background-color: var(--tenable-btn-secondary-disabled-background-color, #415465);
      border-color: var(--tenable-btn-secondary-disabled-border-color, #415465);
    }

    .btn-success {
      color: var(--tenable-btn-success-color, #fff);
      background-color: var(--tenable-btn-success-background-color, #3fae49);
      border-color: var(--tenable-btn-success-border-color, #3fae49);
    }

    .btn-success:hover {
      color: var(--tenable-btn-success-hover-color, #fff);
      background-color: var(--tenable-btn-success-hover-background-color, #35923d);
      border-color: var(--tenable-btn-success-hover-border-color, #35923d);
    }

    .btn-success.disabled,
    .btn-success:disabled {
      color: var(--tenable-btn-success-disabled-color, #fff);
      background-color: var(--tenable-btn-success-disabled-background-color, #3fae49);
      border-color: var(--tenable-btn-success-disabled-border-color, #3fae49);
    }

    .btn-warning {
      color: var(--tenable-btn-warning-color, #fff);
      background-color: var(--tenable-btn-warning-background-color, #fd6219);
      border-color: var(--tenable-btn-warning-border-color, #fd6219);
    }

    .btn-warning:hover {
      color: var(--tenable-btn-warning-hover-color, #fff);
      background-color: var(--tenable-btn-warning-hover-background-color, #ee4e02);
      border-color: var(--tenable-btn-warning-hover-border-color, #ee4e02);
    }

    .btn-warning.disabled,
    .btn-warning:disabled {
      color: var(--tenable-btn-warning-disabled-color, #fff);
      background-color: var(--tenable-btn-warning-disabled-background-color, #fd6219);
      border-color: var(--tenable-btn-warning-disabled-border-color, #fd6219);
    }

    .btn-link {
      color: var(--tenable-btn-link-color, #0079dd);
      text-decoration: none;
      background: none;
      width: inherit;
      padding: 0;
    }

    .btn-link:hover {
      color: var(--tenable-btn-link-hover-color, #004f91);
      text-decoration: underline;
    }

    .btn-link.disabled,
    .btn-link:disabled {
      color: var(--tenable-btn-link-disabled-color, #6c757d);
    }
  `,
];
